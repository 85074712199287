import { Input, Slider as MuiSlider, SliderProps, styled } from '@mui/material';
import React, { useState } from 'react';
import { BeveledContainer } from '../BeveledContainer';
import { Text } from '../Text';
import { ActionButton } from '../ActionButton';

type Props = {
    value: number;
    setValue: (value: number) => void;
    label?: string;
    formatValue?: (value: number) => React.ReactNode;
    actionName?: string;
    action?: () => void;
    additionalInfo?: React.ReactNode;
    disableAction?: boolean;
} & Omit<SliderProps, 'value' | 'label' | 'onChange'>;

const Header = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const LabelDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const SliderArea = styled('div')({
    marginTop: '12px',
});

const BigThumbSlider = styled(MuiSlider)(({ theme }) => ({
    color: theme.palette.primary.main,
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: 'white',
        border: '9px solid currentColor',
    },
}));

export const Slider: React.FC<Props> = ({
    value,
    setValue,
    min,
    max,
    step,
    label,
    formatValue,
    actionName,
    action,
    disableAction = false,
    additionalInfo,
    disabled,
    ...rest
}) => {
    const [focus, setFocus] = useState(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        const num = Number(val);
        if (Number.isNaN(num)) return;
        setValue(num);
    };

    const handleFocus = () => setFocus(true);

    const handleBlur = () => {
        setFocus(false);
        if (min && value < min) setValue(min);
        if (max && value > max) setValue(max);
    };

    const handleSliderChange = (_: unknown, val: number | number[]) => {
        let value = Array.isArray(val) ? val[0]! : val;

        if (step && value > step && value !== min && value !== max) value = Math.floor(value / step) * step;

        setValue(value);
    };

    return (
        <BeveledContainer>
            <Header>
                <LabelDiv>
                    {label && (
                        <Text variant="SmallBold" color="secondary">
                            {label}
                        </Text>
                    )}
                    {disabled ? (
                        <Text variant="Title">{formatValue && value ? formatValue(value) : value}</Text>
                    ) : (
                        <Input
                            value={!focus && formatValue ? formatValue(value) : value}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    )}
                </LabelDiv>
                {action && (
                    <ActionButton variant="contained" onClick={action} disabled={disableAction}>
                        {actionName}
                    </ActionButton>
                )}
            </Header>
            <SliderArea>
                <BigThumbSlider
                    value={value}
                    onChange={handleSliderChange}
                    min={min}
                    max={max}
                    step={step}
                    disabled={disabled}
                    {...rest}
                />
            </SliderArea>
            {additionalInfo}
        </BeveledContainer>
    );
};
