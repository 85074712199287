import { Button, styled } from '@mui/material';

export type ButtonSwitchProps = {
    options: Array<{ label: string; value: string }>;
    value: string;
    onChange: (v: string) => void;
};

const Root = styled('div')({
    display: 'flex',
    gap: 8,
});

const OptionButton = styled(Button)({
    borderRadius: 7,
    minWidth: 160,
});

export const ButtonSwitch = ({ options, value, onChange }: ButtonSwitchProps) => {
    return (
        <Root>
            {options.map(o => {
                const active = value === o.value;

                return (
                    <OptionButton
                        key={o.value}
                        onClick={() => onChange(o.value)}
                        color="primary"
                        variant={active ? 'contained' : 'outlined'}
                    >
                        {o.label}
                    </OptionButton>
                );
            })}
        </Root>
    );
};
