export const saveToClient = (data: string | ArrayBuffer | ArrayBufferView | Blob, filename: string) => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const blobURL = window.URL.createObjectURL(blob);
    saveToClientURLContent(blobURL, filename);
    window.URL.revokeObjectURL(blobURL);
};

export const saveToClientURLContent = (url: string, filename: string) => {
    let tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = url;
    tempLink.setAttribute('download', filename);

    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
};

export const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(String(reader.result));
        reader.onerror = reject;
    });

export const base64ToArrayBuffer = (data: string) => {
    const binaryString = atob(data);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};
