import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, styled, Typography } from '@mui/material';

type FormikCheckboxFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends boolean ? TFieldName : never;
    formik: FormikContextType<TValues>;
    label?: React.ReactNode;
} & Omit<CheckboxProps, 'value' | 'onChange'>;

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

export function FormikCheckBoxField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    label,
    ...textFieldProps
}: FormikCheckboxFieldProps<TValues, TFieldName>) {
    const handleChange = useCallback(
        (value: boolean) => {
            formik.setFieldTouched(name, true).then(() => formik.setFieldValue(name, value));
        },
        [formik, name]
    );

    const error = formik.touched[name] ? formik.errors[name] : null;

    return (
        <Root>
            <FormControlLabel
                control={
                    <Checkbox
                        name={name}
                        value={formik.values[name]}
                        onChange={(_, value) => handleChange(value)}
                        onBlur={formik.handleBlur}
                        disabled={disabled || formik.isSubmitting}
                        {...textFieldProps}
                    />
                }
                label={label}
            />
            {error && (
                <Typography color={'error'} variant={'body2'}>
                    <>{error}</>
                </Typography>
            )}
        </Root>
    );
}
