import { DepositInfo } from './DepositInfoDto';
import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE } from './util';
import { DepositCondition } from './DepositConditionDto';
import { JsonDate } from './util';

@JsonObject()
export class DepositDocument {
    @JsonProperty()
    id: string = '';

    @JsonProperty({ name: 'type_name' })
    name: string = '';
}

@JsonObject()
export class DepositOperation {
    @JsonProperty()
    id: string = '';

    @JsonDate({ name: 'created_at' })
    date: Date = DEFAULT_DATE;

    @JsonProperty()
    sum: number = 0;

    @JsonProperty({ name: 'type_name' })
    typeName: string = '';

    @JsonProperty({ name: 'type_icon' })
    typeIcon: string = '';
}

@JsonObject()
export class DepositDetailedInfo extends DepositInfo {
    @JsonDate({ name: 'contract_date' })
    contractDate: Date | null = null;

    @JsonProperty({ name: 'contract_number' })
    contractNumber: String = '';

    @JsonProperty({ type: DepositCondition })
    conditions: DepositCondition[] = [];

    @JsonProperty({ type: DepositDocument })
    documents: DepositDocument[] = [];

    @JsonProperty({ type: DepositOperation })
    operations: DepositOperation[] = [];
}
