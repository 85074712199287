import { meUserRequest } from '../store/meUserRequest';
import { authStore } from '../store/authStore';
import { User } from '../dto';

export const reloadUser = () =>
    meUserRequest.request().then(user => {
        authStore.setUser(user);

        return user;
    });

export const getFullUserName = (user: User) => {
    return `${user.name} ${user.patronymic ? user.patronymic + ' ' : ''}${user.surname}`;
};
