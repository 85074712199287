import React, { FC } from 'react';
import { Grid, styled } from '@mui/material';
import { formatCurrency, formatPercent } from '../../../utils/formatters';
import { Text } from '../../../components/Text';
import { Colors } from '../../../theme/colors';
import { AddCircleOutline, PieChartOutline, WalletOutlined, WorkOutline } from '@mui/icons-material';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { isDepositActive } from '../../../utils/deposits';
import { DepositDetailedInfo } from '../../../dto';

type Props = {
    deposit: DepositDetailedInfo;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

type CardProps = {
    name: string;
    icon: React.ReactNode;
    value: string;
};

const ParamCard: FC<CardProps> = ({ name, icon, value }) => {
    return (
        <BeveledContainer>
            <Row>
                <Text variant="Small">{name}</Text>
                {icon}
            </Row>
            <Text variant="LargeTitle" color={Colors.darkBlue}>
                {value}
            </Text>
        </BeveledContainer>
    );
};

export const DepositParams: FC<Props> = ({ deposit }) => {
    return (
        <Root>
            <Grid container spacing={'15px'}>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Ваши пополнения'}
                        icon={<WorkOutline />}
                        value={formatCurrency(deposit.sum || deposit.firstPayment)}
                    />
                </Grid>
                {isDepositActive(deposit) && (
                    <>
                        <Grid item xs={6}>
                            <ParamCard
                                name={'Ваш баланс'}
                                icon={<WalletOutlined />}
                                value={formatCurrency(deposit.sumTotal)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ParamCard
                                name={'Ваш доход за месяц'}
                                icon={<AddCircleOutline />}
                                value={`+ ${formatCurrency(deposit.lastMonthProfit)}`}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={6}>
                    <ParamCard
                        name={'Доходность ваших инвестиций'}
                        icon={<PieChartOutline />}
                        value={formatPercent(deposit.profitPercent)}
                    />
                </Grid>
            </Grid>
        </Root>
    );
};
