import { FakeRequest } from '../../api/FakeRequest';
import { ChangePasswordInput, serializer } from '../../dto';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';

export const changePasswordRequest = !mockApi
    ? new HttpRequest<ChangePasswordInput, void>({
          method: 'post',
          path: () => '/users/change-password/',
          mapper: data => data,
          body: data => serializer.serializeObject(data) || {},
      })
    : new FakeRequest<ChangePasswordInput, void>({
          fakeData: () => {},
      });
