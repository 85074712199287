import { Breadcrumbs as MuiBreadcrumbs, Link } from '@mui/material';
import { Text } from '../Text';

type Path = {
    link?: string;
    title: string;
};

type Props = {
    paths: Array<Path>;
};

export const Breadcrumbs = ({ paths }: Props) => {
    return (
        <MuiBreadcrumbs separator={'<'}>
            {paths.map(p => {
                return p.link ? (
                    <Link key={p.title} underline="always" href={p.link}>
                        <Text key={p.title} color="inherit" variant="Tiny">
                            {p.title}
                        </Text>
                    </Link>
                ) : (
                    <Text key={p.title} color="inherit" variant="Tiny">
                        {p.title}
                    </Text>
                );
            })}
        </MuiBreadcrumbs>
    );
};
