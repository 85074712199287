import { Typography, TypographyProps, TypographyVariant } from '@mui/material';
import React from 'react';

const CustomVariants = {
    HeaderBold: { fontSize: 24, fontWeight: 700 },
    TitleBold: { fontSize: 16, fontWeight: 700 },
    Title: { fontSize: 16, fontWeight: 400 },
    LargeTitle: { fontSize: 20, fontWeight: 700 },
    Body: { fontSize: 14, fontWeight: 400 },
    SmallHeavy: { fontSize: 12, fontWeight: 700 },
    SmallBold: { fontSize: 12, fontWeight: 500 },
    Small: { fontSize: 12, fontWeight: 400 },
    Tiny: { fontSize: 10, fontWeight: 400 },
} as const;

const ellipsisStyle = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
} as const;

type CustomVariant = keyof typeof CustomVariants;

export type IVariant = TypographyVariant | CustomVariant;

export type TextProps = Omit<TypographyProps, 'variant'> & {
    variant?: IVariant;
    ellipsis?: boolean;
};

const isMUIVariant = (v: IVariant): v is TypographyVariant => !CustomVariants[v as CustomVariant];

export const Text = ({ variant, ellipsis, ...props }: TextProps) => {
    const textStyle = variant && !isMUIVariant(variant) ? CustomVariants[variant] : undefined;
    const muiVariant = variant && isMUIVariant(variant) ? variant : undefined;

    const style = { ...textStyle, ...(ellipsis && ellipsisStyle) };

    return <Typography {...props} variant={muiVariant} style={style} />;
};
