export const Logo = () => {
    return (
        <svg width="159" height="32" viewBox="0 0 159 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4946_8131)">
                <path
                    d="M-4.98838e-05 19.8394V8.00201C-0.00311973 7.90192 0.0144813 7.80226 0.0516444 7.70914C0.0888075 7.61602 0.144762 7.53141 0.216086 7.46047C0.285799 7.39071 0.369105 7.33573 0.460943 7.2989C0.552781 7.26208 0.651229 7.24417 0.750277 7.24627H3.49871C3.59909 7.2433 3.69901 7.26078 3.7923 7.29762C3.88559 7.33446 3.97026 7.3899 4.04107 7.46047C4.11035 7.53248 4.16442 7.61748 4.20013 7.71047C4.23584 7.80346 4.25245 7.90257 4.24901 8.00201V12.3587H9.62358V8.00201C9.62017 7.90185 9.63756 7.80206 9.67467 7.70884C9.71179 7.61562 9.76781 7.53097 9.83931 7.4601C9.91081 7.38924 9.99626 7.33369 10.0903 7.29691C10.1844 7.26012 10.2851 7.24289 10.3861 7.24627H13.1223C13.2221 7.24288 13.3216 7.2602 13.4143 7.29708C13.5069 7.33396 13.5908 7.38961 13.6606 7.46047C13.7304 7.53229 13.7851 7.61715 13.8215 7.71013C13.8579 7.8031 13.8753 7.90231 13.8726 8.00201V19.8394C13.8767 19.9379 13.86 20.0362 13.8237 20.128C13.7874 20.2198 13.7322 20.3032 13.6616 20.3727C13.5911 20.4423 13.5067 20.4965 13.4139 20.532C13.321 20.5675 13.2217 20.5835 13.1223 20.5789H10.3861C10.2854 20.5825 10.185 20.5657 10.0911 20.5296C9.99715 20.4935 9.9116 20.4387 9.83969 20.3688C9.7693 20.2997 9.71385 20.2171 9.67669 20.1261C9.63953 20.0351 9.62146 19.9375 9.62358 19.8394V15.3008H4.24901V19.8394C4.25243 19.9383 4.23498 20.0368 4.19777 20.1287C4.16055 20.2205 4.1044 20.3037 4.0329 20.3728C3.96223 20.4407 3.87857 20.494 3.78683 20.5294C3.69508 20.5648 3.59712 20.5816 3.49871 20.5789H0.750277C0.65051 20.5827 0.551003 20.5664 0.457782 20.531C0.364561 20.4955 0.279557 20.4417 0.207922 20.3728C0.138495 20.3026 0.0842406 20.219 0.0484835 20.1273C0.0127264 20.0356 -0.00378695 19.9376 -4.98838e-05 19.8394Z"
                    fill="black"
                />
                <path
                    d="M19.5041 21.8439L21.1108 22.054C22.2661 22.054 23.0722 21.2148 23.5289 19.5362C22.2496 19.5767 21.0034 19.1298 20.0465 18.2874C19.114 17.4549 18.312 15.973 17.6405 13.8418L15.8055 7.97773C15.7798 7.90506 15.7674 7.82842 15.7688 7.75141C15.7846 7.63481 15.8391 7.52674 15.9238 7.44426C15.9808 7.37931 16.0519 7.32783 16.1316 7.29357C16.2113 7.25931 16.2977 7.24313 16.3846 7.24624H18.9495C19.1966 7.2503 19.4357 7.33401 19.6305 7.48469C19.8288 7.63104 19.9722 7.83862 20.0383 8.07472L21.6449 13.2316C21.8906 14.174 22.305 15.0652 22.8683 15.8626C23.0413 16.0861 23.2634 16.2678 23.5177 16.3939C23.7721 16.5199 24.052 16.5869 24.3363 16.59L26.783 8.07472C26.8468 7.84279 26.9823 7.63649 27.1704 7.48469C27.3493 7.33121 27.578 7.24658 27.8147 7.24624H30.404C30.4901 7.23956 30.5766 7.25415 30.6556 7.28871C30.7345 7.32327 30.8036 7.37672 30.8567 7.44426C30.94 7.53204 30.9929 7.64392 31.0076 7.76355C31.0091 7.83571 31.0009 7.90774 30.9831 7.97773L27.7657 19.3382C27.1921 21.3508 26.4826 22.7869 25.6371 23.6463C25.2128 24.0731 24.7039 24.408 24.1424 24.6299C23.5809 24.8518 22.979 24.9559 22.3749 24.9355C21.4305 24.9586 20.4858 24.91 19.5489 24.7901C19.1766 24.7621 18.8181 24.6381 18.5091 24.4304C18.443 24.3661 18.3905 24.2894 18.3548 24.2047C18.3191 24.12 18.3008 24.0291 18.3011 23.9373V22.5996C18.2977 22.5002 18.3143 22.401 18.35 22.3081C18.3858 22.2151 18.4398 22.1301 18.5091 22.0581C18.5798 21.9873 18.6644 21.9317 18.7577 21.8949C18.8511 21.858 18.9511 21.8406 19.0515 21.8439H19.5041Z"
                    fill="black"
                />
                <path
                    d="M32.7733 23.9454V8.00201C32.7702 7.90192 32.7878 7.80226 32.825 7.70914C32.8621 7.61602 32.9181 7.53141 32.9894 7.46047C33.0588 7.39032 33.1421 7.33509 33.234 7.29824C33.3259 7.26139 33.4245 7.24369 33.5236 7.24627H35.8928C35.9927 7.24454 36.0918 7.26303 36.1842 7.30061C36.2766 7.33819 36.3603 7.39407 36.4302 7.46481C36.5 7.53556 36.5546 7.6197 36.5905 7.71207C36.6264 7.80445 36.6429 7.9031 36.639 8.00201V8.36575C37.9522 7.45488 39.522 6.97823 41.1246 7.00379C41.9258 6.98542 42.7218 7.13587 43.4599 7.4452C44.1981 7.75454 44.8615 8.21571 45.4063 8.79818C46.5535 9.99444 47.1271 11.7053 47.1271 13.9308C47.1271 16.1562 46.5331 17.8617 45.3451 19.0472C44.1571 20.23 42.5573 20.8228 40.5456 20.8255C39.3382 20.795 38.1424 20.5852 36.9979 20.2031V23.9454C37.0015 24.0452 36.9845 24.1447 36.9481 24.2378C36.9116 24.3309 36.8564 24.4157 36.7858 24.487C36.7161 24.5568 36.6328 24.6117 36.541 24.6485C36.4491 24.6854 36.3507 24.7033 36.2516 24.7012H33.5032C33.4041 24.7037 33.3055 24.6861 33.2136 24.6492C33.1217 24.6124 33.0384 24.5571 32.969 24.487C32.9014 24.4144 32.8491 24.329 32.8155 24.236C32.7819 24.1429 32.7675 24.0441 32.7733 23.9454ZM37.0101 17.3943C37.8692 17.6905 38.768 17.8581 39.677 17.8914C40.7997 17.8914 41.5949 17.5748 42.0625 16.9416C42.5301 16.3085 42.7652 15.2995 42.768 13.9146C42.768 12.5284 42.5478 11.5181 42.0992 10.8916C41.8738 10.5788 41.5717 10.3282 41.2213 10.1634C40.871 9.99864 40.484 9.92518 40.097 9.94998C39.2121 9.94998 38.1804 10.3137 37.0101 11.0452V17.3943Z"
                    fill="black"
                />
                <path
                    d="M77.5149 19.8394V8.00201C77.5113 7.9022 77.5283 7.80271 77.5647 7.70961C77.6012 7.6165 77.6564 7.53175 77.7269 7.46047C77.7967 7.39071 77.88 7.33573 77.9718 7.2989C78.0636 7.26208 78.1621 7.24417 78.2612 7.24627H80.8261C80.9271 7.24289 81.0278 7.26012 81.1219 7.29691C81.2159 7.33369 81.3014 7.38924 81.3729 7.4601C81.4444 7.53097 81.5004 7.61562 81.5375 7.70884C81.5746 7.80206 81.592 7.90185 81.5886 8.00201V15.996L85.907 8.12325C86.048 7.86464 86.2564 7.6483 86.5105 7.49683C86.7437 7.34134 87.0166 7.25447 87.2975 7.24627H90.7311C90.8321 7.24289 90.9328 7.26012 91.0268 7.29691C91.1209 7.33369 91.2064 7.38924 91.2779 7.4601C91.3494 7.53097 91.4054 7.61562 91.4425 7.70884C91.4796 7.80206 91.497 7.90185 91.4936 8.00201V19.8394C91.497 19.9383 91.4795 20.0368 91.4423 20.1287C91.4051 20.2205 91.349 20.3037 91.2775 20.3728C91.2051 20.4418 91.1194 20.4957 91.0255 20.5311C90.9316 20.5665 90.8314 20.5828 90.7311 20.5789H88.1661C88.0666 20.5828 87.9673 20.5662 87.8747 20.53C87.782 20.4938 87.6979 20.4389 87.6279 20.3688C87.5593 20.2987 87.5056 20.2158 87.4699 20.1249C87.4342 20.034 87.4172 19.9369 87.4199 19.8394V11.8575L83.0893 19.706C82.9437 19.9567 82.7378 20.1678 82.4898 20.3203C82.2583 20.4803 81.9849 20.5701 81.7028 20.5789H78.253C78.1538 20.5835 78.0546 20.5676 77.962 20.532C77.8693 20.4965 77.7852 20.4422 77.715 20.3726C77.6447 20.3029 77.5899 20.2196 77.5541 20.1277C77.5182 20.0359 77.5021 19.9377 77.5068 19.8394H77.5149Z"
                    fill="black"
                />
                <path
                    d="M94.5071 19.8394V8.00203C94.504 7.90193 94.5216 7.80228 94.5588 7.70916C94.596 7.61604 94.6519 7.53142 94.7233 7.46049C94.793 7.39073 94.8763 7.33575 94.9681 7.29892C95.0599 7.2621 95.1584 7.24419 95.2574 7.24628H98.0059C98.1063 7.24258 98.2065 7.25972 98.2999 7.2966C98.3933 7.33349 98.4779 7.38932 98.5482 7.46049C98.6175 7.5325 98.6716 7.6175 98.7073 7.71049C98.743 7.80348 98.7596 7.90259 98.7562 8.00203V12.3587H104.131V8.00203C104.127 7.90187 104.145 7.80208 104.182 7.70886C104.219 7.61564 104.275 7.53098 104.346 7.46012C104.418 7.38926 104.503 7.33371 104.597 7.29692C104.692 7.26014 104.792 7.2429 104.893 7.24628H107.629C107.73 7.24332 107.83 7.2608 107.923 7.29764C108.016 7.33448 108.101 7.38992 108.172 7.46049C108.241 7.5325 108.295 7.6175 108.331 7.71049C108.367 7.80348 108.383 7.90259 108.38 8.00203V19.8394C108.384 19.9376 108.367 20.0356 108.331 20.1273C108.296 20.2191 108.241 20.3026 108.172 20.3728C108.1 20.4414 108.015 20.4949 107.922 20.5303C107.829 20.5657 107.729 20.5823 107.629 20.5789H104.893C104.793 20.5825 104.692 20.5657 104.598 20.5296C104.504 20.4935 104.419 20.4388 104.347 20.3688C104.276 20.2997 104.221 20.2171 104.184 20.1261C104.147 20.0351 104.129 19.9375 104.131 19.8394V15.3008H98.748V19.8394C98.7515 19.9383 98.734 20.0368 98.6968 20.1287C98.6596 20.2206 98.6034 20.3037 98.5319 20.3728C98.4615 20.4411 98.3779 20.4946 98.2861 20.53C98.1943 20.5655 98.0962 20.5821 97.9977 20.5789H95.2493C95.1501 20.5826 95.0513 20.5663 94.9587 20.5308C94.8662 20.4954 94.7819 20.4416 94.711 20.3728C94.6417 20.3027 94.5878 20.219 94.5527 20.1272C94.5176 20.0354 94.5021 19.9374 94.5071 19.8394Z"
                    fill="black"
                />
                <path
                    d="M112.254 19.706C111.731 19.3608 111.307 18.8886 111.022 18.3346C110.736 17.7806 110.599 17.1632 110.623 16.5415C110.597 15.923 110.734 15.3084 111.02 14.758C111.306 14.2076 111.731 13.7401 112.254 13.4013C113.508 12.6277 114.969 12.2474 116.446 12.3102H120.523C120.523 11.3591 120.322 10.6976 119.92 10.3258C119.512 9.95401 118.819 9.76406 117.816 9.76406C116.813 9.76406 116.111 10.0106 115.704 10.5036C115.53 10.7323 115.305 10.9179 115.047 11.0455C114.789 11.1732 114.504 11.2395 114.215 11.2392H112.384C112.283 11.2422 112.183 11.2248 112.089 11.188C111.995 11.1512 111.909 11.0957 111.838 11.025C111.767 10.9559 111.712 10.8733 111.675 10.7823C111.638 10.6913 111.62 10.5937 111.622 10.4956C111.622 8.1677 113.852 7.00378 118.313 7.00378C120.262 7.00378 121.826 7.4362 123.003 8.30107C124.18 9.16593 124.768 10.5023 124.768 12.3102V19.8394C124.773 19.9377 124.757 20.0359 124.721 20.1277C124.685 20.2196 124.63 20.3029 124.56 20.3726C124.49 20.4422 124.406 20.4965 124.313 20.532C124.221 20.5676 124.121 20.5835 124.022 20.5789H121.653C121.553 20.5834 121.453 20.567 121.359 20.5308C121.266 20.4946 121.181 20.4394 121.111 20.3688C121.042 20.2987 120.988 20.2158 120.953 20.1249C120.917 20.034 120.9 19.9369 120.903 19.8394V19.4595C119.595 20.3827 118.022 20.8632 116.417 20.8295C114.946 20.8858 113.493 20.4937 112.254 19.706ZM115.385 15.4827C115.227 15.6147 115.099 15.7795 115.012 15.9656C114.924 16.1517 114.879 16.3544 114.879 16.5597C114.879 16.765 114.924 16.9678 115.012 17.1539C115.099 17.3399 115.227 17.5048 115.385 17.6368C115.806 17.9444 116.323 18.0946 116.845 18.0611C118.142 18.0513 119.407 17.671 120.491 16.9659V15.0543H116.723C116.24 15.0355 115.766 15.1873 115.385 15.4827Z"
                    fill="black"
                />
                <path
                    d="M127.778 19.8394V8.00203C127.774 7.90222 127.791 7.80273 127.828 7.70963C127.864 7.61652 127.919 7.53176 127.99 7.46049C128.06 7.39073 128.143 7.33575 128.235 7.29892C128.327 7.2621 128.425 7.24419 128.524 7.24628H131.277C131.376 7.2429 131.476 7.26021 131.569 7.2971C131.661 7.33398 131.745 7.38963 131.815 7.46049C131.884 7.5325 131.938 7.6175 131.974 7.71049C132.01 7.80348 132.026 7.90259 132.023 8.00203V12.3587H137.401V8.00203C137.397 7.90207 137.414 7.80237 137.451 7.70916C137.488 7.61595 137.543 7.53124 137.614 7.46032C137.686 7.38939 137.771 7.33377 137.865 7.29695C137.959 7.26012 138.059 7.24287 138.16 7.24628H140.896C140.997 7.24258 141.097 7.25972 141.19 7.2966C141.284 7.33349 141.368 7.38932 141.438 7.46049C141.508 7.5325 141.562 7.6175 141.598 7.71049C141.633 7.80348 141.65 7.90259 141.646 8.00203V19.8394C141.651 19.9381 141.635 20.0367 141.599 20.1288C141.563 20.2209 141.508 20.3044 141.437 20.3741C141.366 20.4438 141.282 20.498 141.188 20.5333C141.095 20.5686 140.996 20.5841 140.896 20.5789H138.16C138.059 20.5825 137.959 20.5657 137.865 20.5296C137.771 20.4935 137.685 20.4388 137.613 20.3688C137.544 20.2995 137.489 20.2168 137.452 20.1258C137.416 20.0347 137.399 19.9373 137.401 19.8394V15.3008H132.023V19.8394C132.027 19.9384 132.01 20.0372 131.973 20.1291C131.935 20.2211 131.879 20.3042 131.807 20.3728C131.737 20.4406 131.654 20.4938 131.563 20.5292C131.472 20.5646 131.374 20.5815 131.277 20.5789H128.524C128.425 20.5835 128.326 20.5676 128.233 20.532C128.14 20.4965 128.056 20.4422 127.986 20.3726C127.916 20.303 127.861 20.2196 127.825 20.1278C127.789 20.0359 127.773 19.9377 127.778 19.8394Z"
                    fill="black"
                />
                <path
                    d="M151.189 20.8255C148.891 20.8255 147.105 20.2408 145.83 19.0715C144.555 17.9022 143.918 16.1846 143.918 13.9187C143.918 11.6528 144.551 9.93381 145.818 8.76179C147.085 7.58978 148.852 7.00378 151.119 7.00378C153.381 7.00378 155.121 7.36076 156.339 8.07475C156.909 8.38281 157.38 8.84203 157.702 9.40094C158.023 9.95985 158.181 10.5964 158.157 11.2392C158.161 11.3378 158.145 11.4362 158.108 11.528C158.072 11.6199 158.016 11.7032 157.945 11.7727C157.873 11.8413 157.787 11.8949 157.693 11.9303C157.599 11.9657 157.499 11.9822 157.399 11.9788H155.568C155.275 11.9668 154.991 11.8786 154.743 11.7233C154.496 11.568 154.293 11.3508 154.157 11.0937C153.676 10.3285 152.824 9.94728 151.6 9.94998C150.377 9.94998 149.521 10.2571 149.035 10.8795C148.55 11.5019 148.301 12.4961 148.301 13.9146C148.301 15.3332 148.554 16.3395 149.06 16.9538C149.566 17.5681 150.438 17.8914 151.686 17.8914C152.934 17.8914 153.798 17.5115 154.28 16.7477C154.414 16.4928 154.614 16.2772 154.858 16.122C155.103 15.9668 155.384 15.8774 155.674 15.8626H157.505C157.606 15.8595 157.707 15.877 157.801 15.9138C157.895 15.9506 157.98 16.0061 158.051 16.0768C158.122 16.1459 158.177 16.2284 158.214 16.3195C158.252 16.4105 158.27 16.5081 158.268 16.6062C158.291 17.2495 158.133 17.8865 157.811 18.4456C157.489 19.0046 157.016 19.4635 156.445 19.7706C155.23 20.4712 153.478 20.8228 151.189 20.8255Z"
                    fill="black"
                />
                <path
                    d="M57.2972 16.4971V4.19098C57.2972 4.19098 53.4844 5.46808 53.252 5.56912C52.3782 5.94741 51.6339 6.5687 51.1092 7.35774C50.5844 8.14678 50.3019 9.0697 50.2956 10.0147V19.9647C50.303 20.9089 50.5856 21.8308 51.1094 22.6195C51.6332 23.4082 52.3758 24.0301 53.2479 24.4102C53.4804 24.5113 57.2972 25.7883 57.2972 25.7883V20.979H55.4459L57.2972 16.4971Z"
                    fill="#60AAFB"
                />
                <path
                    d="M65.7831 8.40616V0C65.7831 0 61.9662 1.28112 61.7379 1.37811C60.8647 1.75725 60.121 2.37877 59.5964 3.16762C59.0717 3.95648 58.7887 4.87893 58.7815 5.82368V16.5537L60.6043 20.9993H58.7815C58.7815 26.7825 58.7815 32.0162 58.7815 32.0162C58.7815 32.0162 62.5983 30.735 62.8307 30.634C63.7036 30.2544 64.4471 29.6328 64.9716 28.8441C65.4961 28.0553 65.7793 27.1331 65.7871 26.1884V12.9528H63.9236L65.7831 8.40616Z"
                    fill="#355DFB"
                />
                <path
                    d="M74.2078 10.0187C74.2016 9.07368 73.919 8.15078 73.3942 7.36174C72.8695 6.5727 72.1252 5.95139 71.2514 5.57309C71.019 5.47206 67.2062 4.19498 67.2062 4.19498V8.44252L69.0657 12.9568H67.2062V25.8004C67.2062 25.8004 71.023 24.5233 71.2555 24.4223C72.1276 24.0422 72.8702 23.4203 73.394 22.6316C73.9178 21.8429 74.2004 20.9209 74.2078 19.9767V10.0187Z"
                    fill="#0000DE"
                />
            </g>
            <defs>
                <clipPath id="clip0_4946_8131">
                    <rect width="158.264" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
