import { FormHelperText, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { uniqueId } from 'lodash';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

const noWrapAndEllipsisProperty = { noWrap: true };

export type SelectProps<TValue> = Omit<MuiSelectProps, 'onChange' | 'renderValue'> & {
    items: Array<TValue>;
    renderValue: (v: TValue, inList: boolean) => React.ReactNode;
    keyGetter: (v: TValue) => string;
    value: TValue | null | void;
    onChange: (v: TValue) => void;
    label?: ReactNode;
    helperText?: string | null;
    secondaryLabel?: (v: TValue, inList: boolean) => string;
    valueClassName?: string;
    valueCaption?: (v: TValue) => string;
};

export const Select = <TValue extends unknown = string>({
    label,
    items,
    value,
    onChange,
    renderValue,
    keyGetter,
    helperText,
    error,
    secondaryLabel,
    disabled,
    valueClassName,
    valueCaption,
    ...rest
}: SelectProps<TValue>) => {
    const idPart = useMemo(() => uniqueId('custom-select-component'), []);
    const labelId = `${idPart}-label`;
    const selectId = `${idPart}-select`;

    const handleChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const selectedItem = items.find(i => keyGetter(i) === event.target.value);
            if (!selectedItem) return;

            onChange(selectedItem);
        },
        [items, keyGetter, onChange]
    );

    const itemsToRender = useMemo(
        () => (!value || items.some(i => keyGetter(i) === keyGetter(value)) ? items : [...items, value]),
        [items, value, keyGetter]
    );

    const localRenderValue = useCallback(
        (key: string) => {
            const itemToRender = itemsToRender.find(i => keyGetter(i) === key);
            if (!itemToRender) return null;

            return (
                <ListItemText
                    primary={renderValue(itemToRender, false)}
                    secondary={secondaryLabel ? secondaryLabel(itemToRender, false) : null}
                    primaryTypographyProps={noWrapAndEllipsisProperty}
                    secondaryTypographyProps={noWrapAndEllipsisProperty}
                />
            );
        },
        [itemsToRender, keyGetter, renderValue, secondaryLabel]
    );

    return (
        <FormControl error={error} variant="outlined" fullWidth>
            {Boolean(label) && (
                <InputLabel error={error} id={labelId} style={{ transform: 'none' }}>
                    {label}
                </InputLabel>
            )}
            <MuiSelect
                labelId={labelId}
                id={selectId}
                value={value ? keyGetter(value) : ''}
                label={label}
                onChange={(event, _) => handleChange(event)}
                renderValue={localRenderValue as MuiSelectProps['renderValue']}
                error={error}
                {...rest}
            >
                {itemsToRender.map((value, index) => (
                    <MenuItem key={index} value={keyGetter(value)}>
                        <ListItemText
                            title={valueCaption ? valueCaption(value) : undefined}
                            className={valueClassName}
                            primary={renderValue(value, true)}
                            primaryTypographyProps={{
                                style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
                            }}
                            secondary={secondaryLabel ? secondaryLabel(value, true) : null}
                        />
                    </MenuItem>
                ))}
            </MuiSelect>
            {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};
