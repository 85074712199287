import { HttpRequest } from '../../api/HttpRequest';
import { ProfileInput, getProfileInput, serializer } from '../../dto';
import { mockApi } from '../../api/constants';
import { FakeRequest } from '../../api/FakeRequest';

export const updateProfileRequest = !mockApi
    ? new HttpRequest<ProfileInput, void>({
          method: 'put',
          path: () => '/users/profile/',
          body: input => serializer.serializeObject(getProfileInput(input)) || {},
          mapper: () => {},
      })
    : new FakeRequest<ProfileInput, void>({
          fakeData: () => {},
      });
