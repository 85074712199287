import { FakeRequest } from '../../api/FakeRequest';
import { RegistrationData, serializer } from '../../dto';
import { IRequest } from '../../api/types';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';

export const registerRequest: IRequest<RegistrationData, void> = !mockApi
    ? new HttpRequest({
          method: 'post',
          path: () => '/users/register/',
          body: data => serializer.serializeObject(data) || {},
          mapper: data => data,
      })
    : new FakeRequest<RegistrationData, void>({
          fakeData: () => {},
      });
