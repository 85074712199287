import { FakeRequest } from '../../../api/FakeRequest';
import { mockApi } from '../../../api/constants';
import { HttpRequest } from '../../../api/HttpRequest';
import { base64ToArrayBuffer } from '../../../utils/files';

export const downloadDepositDocumentRequest = !mockApi
    ? new HttpRequest<{ depositId: string; id: string }, ArrayBuffer>({
          method: 'get',
          path: input => `/deposits/${input.depositId}/documents/${input.id}/`,
          mapper: data => base64ToArrayBuffer(data),
      })
    : new FakeRequest<{ depositId: string; id: string }, string>({
          fakeData: () => 'Some data',
      });
