import { Layout } from '../../components/Layout/Layout';
import { Button, LinearProgress, styled, TextField, Typography, Box } from '@mui/material';
import { LockOpen as LockOpenLink, ArrowForwardIos as ArrowForwardIcon, AppRegistration } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { loginStore } from './loginStore';
import { loginRequest } from './loginRequest';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { PasswordField } from '../../components/Inputs';
import { Link } from '../../components/Link';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
});

const LinksArea = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

export const LoginPage = observer(() => {
    const disabled = loginRequest.isLoading;

    return (
        <Layout title="Нурфинанс">
            <Root>
                <div>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 'h6.fontSize' }}>Вход в личный кабинет</Box>
                    </Typography>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'regular' }}>Введите вашу почту и пароль</Box>
                    </Typography>
                </div>
                <div />
                <TextField
                    label="E-mail"
                    value={loginStore.login}
                    onChange={loginStore.onLoginChange}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                />
                <PasswordField
                    autoComplete="off"
                    label="Пароль"
                    value={loginStore.password}
                    onChange={loginStore.onPasswordChange}
                    disabled={disabled}
                    InputLabelProps={{ shrink: true }}
                />
                <div />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!loginStore.canSubmit || disabled}
                    onClick={loginStore.submit}
                    endIcon={<ArrowForwardIcon fontSize="large" />}
                >
                    Продолжить
                </Button>
                {loginRequest.isLoading && <LinearProgress />}
                <LinksArea>
                    <Link color="primary.main" to="/register">
                        <AppRegistration /> Зарегистрироваться
                    </Link>
                </LinksArea>
            </Root>
        </Layout>
    );
});
