export const DELTA_SUM = 1000;
export const DELTA_DURATION = 1;

export const DEFAULT_REPLENISHMENT = 100000;
export const MAX_REPLENISHMENT = 5 * DEFAULT_REPLENISHMENT;
export const MIN_REPLENISHMENT = 0;
export const STEP_REPLENISHMENT = 1000;

export const PER_MONTH_DEPOSIT_ID = '6';
export const END_TERM_DEPOSIT_ID = '7';
