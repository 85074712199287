import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class ChangePasswordInput {
    @JsonProperty({ name: 'old_password' })
    oldPassword: string = '';

    @JsonProperty({ name: 'new_password' })
    newPassword: string = '';

    @JsonProperty({ name: 'password_confirmation' })
    passwordConfirmation: string = '';
}

export const getChangePasswordInput = (input: ChangePasswordInput) => Object.assign(new ChangePasswordInput(), input);
