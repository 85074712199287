import { Link } from '@mui/material';
import { Text } from '../Text';
import React, { FC } from 'react';

type Props = {
    onBack: () => void;
};

export const BackButton: FC<Props> = ({ onBack }) => {
    return (
        <Link component="button" onClick={onBack}>
            <Text variant="Small" color="primary">
                &lt;&nbsp;Назад
            </Text>
        </Link>
    );
};
