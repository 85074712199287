import numbro from 'numbro';
import { isNumber } from 'lodash';
import { DocumentStatus } from '../types';

const NBSP = String.fromCharCode(160);

numbro.registerLanguage(
    {
        languageTag: 'ru-RU',
        delimiters: {
            thousands: NBSP,
            decimal: '.',
        },
        abbreviations: {
            thousand: 'тыс',
            million: 'млн',
            billion: 'млрд',
            trillion: 'трлн',
        },
        ordinal: (value: number) => `${value}`,
        currency: {
            symbol: '₽',
            position: 'postfix',
            code: 'RUB',
        },
        currencyFormat: {
            thousandSeparated: true,
            spaceSeparated: true,
            spaceSeparatedCurrency: true,
            mantissa: 0,
            average: false,
            currencyPosition: 'postfix',
        },
        defaults: {
            thousandSeparated: true,
        },
        formats: {
            fourDigits: {
                totalLength: 4,
                spaceSeparated: true,
                average: true,
            },
            fullWithTwoDecimals: {
                output: 'currency',
                mantissa: 2,
                spaceSeparated: true,
                thousandSeparated: true,
            },
            fullWithTwoDecimalsNoCurrency: {
                mantissa: 2,
                thousandSeparated: true,
            },
            fullWithNoDecimals: {
                output: 'currency',
                spaceSeparated: true,
                thousandSeparated: true,
                mantissa: 0,
            },
        },
    },
    true
);

export const abbreviations = {
    na: 'Н/Д',
    needCalc: 'Рассчитывается',
} as const;

export const precisions = {
    price: 2,
    percent: 2,
} as const;

export const formatCurrency = (v: number | null | undefined) => {
    if (!isNumber(v)) return abbreviations.na;

    return numbro(v).formatCurrency();
};

export const formatPercent = (v: number | null | undefined) => {
    if (!v) return abbreviations.needCalc;

    return numbro(v).format({
        output: 'percent',
        mantissa: precisions.percent,
    });
};

export const formatDate = (v: Date | null | undefined) => {
    if (!v) return abbreviations.na;

    return v.toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });
};

export const formatDateTime = (v: Date | null | undefined) => {
    if (!v) return abbreviations.na;

    return v.toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });
};

export const formatDocumentStatus = (status: DocumentStatus) => {
    switch (status) {
        case DocumentStatus.APPROVED:
            return 'Проверен';
        case DocumentStatus.NOT_APPROVED:
            return 'Ожидает проверки';
        case DocumentStatus.DENIED:
            return 'Отклонен, требуется повторная загрузка';
    }
};
