import { observer } from 'mobx-react';
import { authStore } from '../../store/authStore';
import { Logout } from '@mui/icons-material';
import { ToolbarButton } from './ToolbarButton';
import React from 'react';

export const LogoutButton = observer(() => {
    if (!authStore.isLoggedIn) return null;

    return <ToolbarButton onClick={authStore.logout} Icon={Logout} label="" color="secondary" />;
});
