import { TextField, TextFieldProps } from '@mui/material';
import { FormikContextType } from 'formik';
import React from 'react';

type FormikTextFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends string ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<TextFieldProps, 'value'>;

export function FormikTextField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    onChange,
    disabled,
    ...textFieldProps
}: FormikTextFieldProps<TValues, TFieldName>) {
    return (
        <TextField
            fullWidth
            name={name}
            value={formik.values[name]}
            onChange={onChange || formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && formik.errors[name]?.toString()}
            disabled={disabled || formik.isSubmitting}
            {...textFieldProps}
        />
    );
}
