import React, { FC } from 'react';
import { Formik } from 'formik';
import { getRegisterFormikConfig } from './RegisterFormik';
import { RegisterForm } from './RegisterForm';
import { useHistory } from 'react-router';

export const RegisterPage: FC = () => {
    const history = useHistory();

    return (
        <Formik {...getRegisterFormikConfig(() => history.push('/'))}>
            <RegisterForm />
        </Formik>
    );
};
