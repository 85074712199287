import { Stack } from '@mui/material';
import { observer } from 'mobx-react';
import { messageStore } from '../../store/messageStore';
import { MessageItem } from './MessageItem';

export const MessageProvider = observer(() => {
    const messages = messageStore.messages.slice();

    return (
        <Stack>
            {messages.map((message, index) => (
                <MessageItem message={message} key={message.key} index={index} />
            ))}
        </Stack>
    );
});
