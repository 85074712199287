import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { PaymentVariant } from '../types';
import { JsonEnum } from './util';

@JsonObject()
class Duration {
    @JsonProperty()
    min: number = 0;

    @JsonProperty()
    max: number = 0;
}

@JsonObject()
export class DepositInterval {
    @JsonProperty({ name: 'min_sum' })
    minSum: number = 0;

    @JsonProperty({ name: 'max_sum' })
    maxSum: number | undefined | null;

    @JsonProperty({ name: 'share_percent' })
    sharePercent: number = 0;

    @JsonProperty({ name: 'min_stat_profit_percent' })
    minStatProfitPercent: number = 0;

    @JsonProperty({ name: 'max_stat_profit_percent' })
    maxStatProfitPercent: number | null | undefined;

    @JsonEnum({ type: PaymentVariant })
    profitPayment: PaymentVariant = PaymentVariant.MONTHLY;

    @JsonProperty({ name: 'allow_replenishment' })
    allowReplenishment: boolean = true;
}

@JsonObject()
export class DepositCondition {
    @JsonProperty({ type: Duration })
    duration: Duration = {
        min: 0,
        max: 0,
    };

    @JsonProperty({ type: DepositInterval })
    intervals: DepositInterval[] = [];
}
