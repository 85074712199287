import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0000DE',
        },
        secondary: {
            main: '#9AB5C6',
        },
        text: {
            secondary: '#9AB5C6',
        },
        background: {
            default: '#F0F4F8',
        },
    },
    typography: {
        h1: {
            fontSize: '2rem',
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    backgroundColor: '#fff',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#9AB5C6',
                    fontWeight: 500,
                    fontSize: 12,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    display: 'relative',
                    height: 66,
                    borderRadius: '28px 0px 28px 0px',
                },
                endIcon: {
                    position: 'absolute',
                    right: 32,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#9AB5C6',
                    textDecorationColor: 'inherit',
                    display: 'flex',
                    gap: 12,
                },
            },
        },
    },
});
