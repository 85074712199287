import { Layout } from '../../components/Layout';
import { Deposits } from './Deposits';
import { Box, IconButton, styled } from '@mui/material';
import { Text } from '../../components/Text';
import { authStore } from '../../store/authStore';
import { observer } from 'mobx-react';
import React from 'react';
import { AddCircle } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { getFullUserName } from '../../utils/users';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

const Header = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Highlight = styled(Box)({
    background: 'lightpink',
    padding: 5,
});

export const HomePage = observer(() => {
    const user = authStore.user;

    const history = useHistory();

    return (
        <Layout title="Home page">
            <Root>
                <Header>
                    <Box>
                        <Text variant="HeaderBold">Личный кабинет</Text>
                        <Text variant="Title">{`Приветствуем, ${getFullUserName(user!)}!`}</Text>
                    </Box>
                    <IconButton onClick={() => history.push('/create')}>
                        <AddCircle fontSize="large" color="primary" />
                    </IconButton>
                </Header>
                {authStore.isNotVerified && (
                    <Highlight>
                        <Text>Ваши данные приняты, ожидайте подтверждения. Обычно это занимает 1 рабочий день</Text>
                    </Highlight>
                )}
                <Deposits />
            </Root>
        </Layout>
    );
});
