import { action, observable, makeObservable } from 'mobx';
import type { IObservableArray } from 'mobx';
import { uniqueId } from 'lodash';
import { isDevelopment } from '../utils/env';

export type MessageType = 'error' | 'warning' | 'info' | 'success';

export type Message = {
    key: string;
    type: MessageType;
    text: string;
};

class MessageStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    messages: IObservableArray<Message> = observable([]);

    @action
    showMessage(type: MessageType, text: string) {
        const message = {
            type,
            text,
            key: uniqueId(),
        };

        this.messages.push(message);
    }

    @action
    hideMessage(message: Message) {
        this.messages.remove(message);
    }

    success = (text: string) => this.showMessage('success', text);
    info = (text: string) => this.showMessage('info', text);
    error = (text: string) => this.showMessage('error', text);
    warning = (text: string) => this.showMessage('warning', text);
}

export const messageStore = new MessageStore();

if (isDevelopment) (window as any).messageStore = messageStore;
