import { PaymentVariant } from '../../types';

export const depositConditionsMock = [
    {
        duration: {
            min: 3,
            max: 5,
        },
        intervals: [
            {
                min_sum: 100000,
                max_sum: 499999,
                share_percent: 0.45,
                min_stat_profit_percent: 0.102,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 500000,
                max_sum: 999999,
                share_percent: 0.45,
                min_stat_profit_percent: 0.102,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 1000000,
                max_sum: 2999999,
                share_percent: 0.45,
                min_stat_profit_percent: 0.102,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 3000000,
                max_sum: 4999999,
                share_percent: 0.45,
                min_stat_profit_percent: 0.102,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 5000000,
                max_sum: null,
                share_percent: 0.7,
                min_stat_profit_percent: 0.159,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
        ],
    },
    {
        duration: {
            min: 6,
            max: 11,
        },
        intervals: [
            {
                min_sum: 100000,
                max_sum: 499999,
                share_percent: 0.5,
                min_stat_profit_percent: 0.114,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 500000,
                max_sum: 999999,
                share_percent: 0.55,
                min_stat_profit_percent: 0.125,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 1000000,
                max_sum: 2999999,
                share_percent: 0.6,
                min_stat_profit_percent: 0.136,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 3000000,
                max_sum: 4999999,
                share_percent: 0.65,
                min_stat_profit_percent: 0.148,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 5000000,
                max_sum: null,
                share_percent: 0.7,
                min_stat_profit_percent: 0.159,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
        ],
    },
    {
        duration: {
            min: 12,
            max: 17,
        },
        intervals: [
            {
                min_sum: 100000,
                max_sum: 499999,
                share_percent: 0.55,
                min_stat_profit_percent: 0.125,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 500000,
                max_sum: 999999,
                share_percent: 0.6,
                min_stat_profit_percent: 0.136,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 1000000,
                max_sum: 2999999,
                share_percent: 0.65,
                min_stat_profit_percent: 0.148,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 3000000,
                max_sum: 4999999,
                share_percent: 0.7,
                min_stat_profit_percent: 0.159,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 5000000,
                max_sum: null,
                share_percent: 0.75,
                min_stat_profit_percent: 0.17,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
        ],
    },
    {
        duration: {
            min: 18,
            max: 23,
        },
        intervals: [
            {
                min_sum: 100000,
                max_sum: 499999,
                share_percent: 0.55,
                min_stat_profit_percent: 0.125,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 500000,
                max_sum: 999999,
                share_percent: 0.6,
                min_stat_profit_percent: 0.136,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 1000000,
                max_sum: 2999999,
                share_percent: 0.65,
                min_stat_profit_percent: 0.148,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 3000000,
                max_sum: 4999999,
                share_percent: 0.7,
                min_stat_profit_percent: 0.159,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 5000000,
                max_sum: null,
                share_percent: 0.75,
                min_stat_profit_percent: 0.17,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
        ],
    },
    {
        duration: {
            min: 24,
            max: 29,
        },
        intervals: [
            {
                min_sum: 100000,
                max_sum: 499999,
                share_percent: 0.6,
                min_stat_profit_percent: 0.136,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 500000,
                max_sum: 999999,
                share_percent: 0.65,
                min_stat_profit_percent: 0.148,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 1000000,
                max_sum: 2999999,
                share_percent: 0.7,
                min_stat_profit_percent: 0.159,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 3000000,
                max_sum: 4999999,
                share_percent: 0.75,
                min_stat_profit_percent: 0.17,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 5000000,
                max_sum: null,
                share_percent: 0.8,
                min_stat_profit_percent: 0.182,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
        ],
    },
    {
        duration: {
            min: 30,
            max: 35,
        },
        intervals: [
            {
                min_sum: 100000,
                max_sum: 499999,
                share_percent: 0.6,
                min_stat_profit_percent: 0.136,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 500000,
                max_sum: 999999,
                share_percent: 0.65,
                min_stat_profit_percent: 0.148,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 1000000,
                max_sum: 2999999,
                share_percent: 0.7,
                min_stat_profit_percent: 0.159,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 3000000,
                max_sum: 4999999,
                share_percent: 0.75,
                min_stat_profit_percent: 0.17,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 5000000,
                max_sum: null,
                share_percent: 0.8,
                min_stat_profit_percent: 0.182,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
        ],
    },
    {
        duration: {
            min: 36,
            max: 36,
        },
        intervals: [
            {
                min_sum: 100000,
                max_sum: 499999,
                share_percent: 0.65,
                min_stat_profit_percent: 0.148,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 500000,
                max_sum: 999999,
                share_percent: 0.7,
                min_stat_profit_percent: 0.159,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 1000000,
                max_sum: 2999999,
                share_percent: 0.75,
                min_stat_profit_percent: 0.17,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 3000000,
                max_sum: 4999999,
                share_percent: 0.8,
                min_stat_profit_percent: 0.182,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
            {
                min_sum: 5000000,
                max_sum: null,
                share_percent: 0.85,
                min_stat_profit_percent: 0.193,
                max_stat_profit_percent: null,
                profit_payment: PaymentVariant.MONTHLY,
                allow_replenishment: true,
            },
        ],
    },
];
