import MuiPhoneNumber, { MuiPhoneNumberProps } from 'mui-phone-number';
import { FormikContextType } from 'formik';
import React, { useCallback } from 'react';

type FormikPhoneFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends string ? TFieldName : never;
    formik: FormikContextType<TValues>;
} & Omit<MuiPhoneNumberProps, 'value' | 'onChange'>;

export function FormikPhoneField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
    disabled,
    ...textFieldProps
}: FormikPhoneFieldProps<TValues, TFieldName>) {
    const handleChange = useCallback(
        (value: string) => {
            formik.setFieldTouched(name, true).then(() => formik.setFieldValue(name, value));
        },
        [formik, name]
    );

    return (
        <MuiPhoneNumber
            fullWidth
            name={name}
            value={formik.values[name]}
            onChange={value => handleChange(value.toString())}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && !!formik.errors[name]}
            helperText={formik.touched[name] && formik.errors[name]?.toString()}
            disabled={disabled || formik.isSubmitting}
            countryCodeEditable={true}
            onlyCountries={['ru']}
            defaultCountry={'ru'}
            {...textFieldProps}
        />
    );
}
