import { FakeRequest } from '../api/FakeRequest';
import { DepositType, serializer } from '../dto';
import { depositConditionsMock } from '../dto/mocks/deposit.mock';
import { IRequest } from '../api/types';
import { HttpRequest } from '../api/HttpRequest';
import { mockApi } from '../api/constants';

export const depositTypesRequest: IRequest<{}, DepositType[]> = !mockApi
    ? new HttpRequest({
          method: 'get',
          path: () => '/deposits/types/',
          mapper: data => serializer.deserializeObjectArray(data, DepositType) as DepositType[],
      })
    : new FakeRequest<{}, DepositType[]>({
          fakeData: () =>
              serializer.deserializeObjectArray(
                  [
                      { id: '1', name: 'Прибыльный', conditions: depositConditionsMock },
                      {
                          id: '2',
                          name: 'Обеспеченное будущее',
                          conditions: [
                              {
                                  duration: {
                                      min: 60,
                                      max: 60,
                                  },
                                  intervals: [
                                      {
                                          min_sum: 100000,
                                          max_sum: 499999,
                                          share_percent: 0.7,
                                          min_stat_profit_percent: 0.159,
                                          max_stat_profit_percent: null,
                                          profit_payment: 'completion',
                                          allow_replenishment: true,
                                      },
                                      {
                                          min_sum: 500000,
                                          max_sum: 999999,
                                          share_percent: 0.75,
                                          min_stat_profit_percent: 0.17,
                                          max_stat_profit_percent: null,
                                          profit_payment: 'completion',
                                          allow_replenishment: true,
                                      },
                                      {
                                          min_sum: 1000000,
                                          max_sum: 2999999,
                                          share_percent: 0.8,
                                          min_stat_profit_percent: 0.182,
                                          max_stat_profit_percent: null,
                                          profit_payment: 'completion',
                                          allow_replenishment: true,
                                      },
                                      {
                                          min_sum: 3000000,
                                          max_sum: 5000000,
                                          share_percent: 0.85,
                                          min_stat_profit_percent: 0.193,
                                          max_stat_profit_percent: null,
                                          profit_payment: 'completion',
                                          allow_replenishment: true,
                                      },
                                  ],
                              },
                          ],
                      },
                  ],
                  DepositType
              ) as DepositType[],
      });
