import { FakeRequest } from '../../api/FakeRequest';
import { serializer } from '../../dto';
import { IRequest } from '../../api/types';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';
import { DepositInput } from '../../dto/DepositInputDto';

export const createDepositRequest: IRequest<DepositInput, void> = !mockApi
    ? new HttpRequest({
          method: 'post',
          path: () => '/deposits/',
          body: data => serializer.serializeObject(data) || {},
          mapper: data => data,
      })
    : new FakeRequest<DepositInput, void>({
          fakeData: () => {},
      });
