import { UserStatus } from '../types';
import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { JsonEnum } from './util';

@JsonObject()
export class User {
    @JsonProperty({ name: 'first_name' })
    name: string = '';

    @JsonProperty({ name: 'last_name' })
    surname: string = '';

    @JsonProperty({ name: 'middle_name' })
    patronymic: string | null | undefined;

    @JsonProperty()
    email: string = '';

    @JsonProperty()
    phone: string = '';

    @JsonProperty({ name: 'tin' })
    TIN: string = '';

    @JsonEnum({ type: UserStatus })
    status: UserStatus = UserStatus.DRAFT;

    @JsonProperty({ name: 'force_password_change' })
    forcePasswordChange: boolean = false;
}
