import React, { FC, useCallback } from 'react';
import { IconButton, styled } from '@mui/material';
import { Text } from '../../../components/Text';
import { Colors } from '../../../theme/colors';
import { ArrowDownward, WorkOutline } from '@mui/icons-material';
import { saveToClient } from '../../../utils/files';
import { messageStore } from '../../../store/messageStore';
import { DepositDetailedInfo, DepositDocument } from '../../../dto';
import { downloadDepositDocumentRequest } from './downloadDepositDocumentRequest';

type Props = {
    deposit: DepositDetailedInfo;
};

const Root = styled('div')({
    marginTop: '18px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.lightestBlue,
    borderRadius: '10px',
    padding: '11px 17px',
});

const Container = styled('div')({
    marginTop: '3px',
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
});

const Item = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const ItemInfo = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '31px',
});

const TinyIconButton = styled(IconButton)({
    padding: '0px',
});

type DocProps = {
    doc: DepositDocument;
    onDownload: () => void;
};

const Document: FC<DocProps> = ({ doc, onDownload }) => {
    return (
        <Item>
            <ItemInfo>
                <WorkOutline />
                <Text variant="Title">{doc.name}</Text>
            </ItemInfo>
            <TinyIconButton onClick={onDownload}>
                <ArrowDownward />
            </TinyIconButton>
        </Item>
    );
};

export const DepositDocuments: FC<Props> = ({ deposit }) => {
    const documents = deposit.documents;

    const download = useCallback(
        (doc: DepositDocument) => {
            downloadDepositDocumentRequest
                .request({
                    depositId: deposit.id,
                    id: doc.id,
                })
                .then(data => saveToClient(data, `${doc.name}.pdf`))
                .then(() => messageStore.showMessage('success', `${doc.name} сохранен`));
        },
        [deposit]
    );

    return (
        <Root>
            <Text variant="SmallBold" color="secondary">
                Документы по вкладу
            </Text>
            {!!documents.length && (
                <Container>
                    {documents.map(doc => (
                        <Document doc={doc} key={doc.id} onDownload={() => download(doc)} />
                    ))}
                </Container>
            )}
        </Root>
    );
};
