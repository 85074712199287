import { HttpRequest } from '../../../api/HttpRequest';
import { mockApi } from '../../../api/constants';
import { FakeRequest } from '../../../api/FakeRequest';
import { base64ToArrayBuffer } from '../../../utils/files';
import { ProfileDocumentType } from '../../../types';

export const getProfileDocumentRequest = !mockApi
    ? new HttpRequest<ProfileDocumentType, ArrayBuffer>({
          method: 'get',
          path: doc_type => `/users/profile/document/${doc_type}/`,
          mapper: data => base64ToArrayBuffer(data),
      })
    : new FakeRequest<string, ArrayBuffer>({ fakeData: () => new ArrayBuffer(0) });
