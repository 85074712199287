import React from 'react';
import { useRequest } from '../../hooks/useRequest';
import { depositsRequest } from './depositsRequest';
import { LinearProgress, styled, Typography } from '@mui/material';
import { DepositItem } from './DepositItem';
import { useHistory } from 'react-router';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
});

const Container = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

export const Deposits = () => {
    const { data, error, loading } = useRequest(depositsRequest, undefined);

    const history = useHistory();

    return (
        <Root>
            {loading && <LinearProgress />}
            {error && <Typography color="error">{error.message}</Typography>}
            {data && (
                <Container>
                    {data.map(deposit => (
                        <DepositItem
                            key={deposit.id}
                            deposit={deposit}
                            onClick={() => {
                                history.push(`/deposits/${deposit.id}`);
                            }}
                        />
                    ))}
                </Container>
            )}
        </Root>
    );
};
