import { observer } from 'mobx-react';
import React from 'react';
import { authStore } from './store/authStore';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { RootNavigation } from './views/RootNavigation';
import { LoginRegisterNavigation } from './views/LoginRegisterNavigation';
import { MessageProvider } from './components/MessageProvider';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';

const history = createBrowserHistory();

export const App = observer(() => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <ThemeProvider theme={theme}>
                <MessageProvider />
                <Router history={history}>
                    {authStore.isLoggedIn ? <RootNavigation /> : <LoginRegisterNavigation />}
                </Router>
            </ThemeProvider>
        </LocalizationProvider>
    );
});
