import React, { FC } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import { useRequest } from '../../../hooks/useRequest';
import { depositsDetailedRequest } from '../depositDetailedRequest';
import { useParams } from 'react-router';
import { Layout } from '../../../components/Layout';
import { Deposit } from './Deposit';

export const DepositPage: FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data, error, loading } = useRequest(depositsDetailedRequest, { id });

    return (
        <Layout>
            {loading && <LinearProgress />}
            {error && <Typography color="error">{error.message}</Typography>}
            {data && <Deposit deposit={data} />}
        </Layout>
    );
};
