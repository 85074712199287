import { Form, Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { ChangePasswordInput, getChangePasswordInput } from '../../dto';
import { getChangePasswordFormik } from './changePasswordFormik';
import { Button, styled } from '@mui/material';
import { FormikPasswordField } from '../../components/FormikFields';
import { BackButton } from '../../components/Layout';
import { changePasswordRequest } from './changePasswordRequest';
import { messageStore } from '../../store/messageStore';
import { reloadUser } from '../../utils/users';

type Props = {
    onBack?: () => void;
    onComplete: () => void;
};

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
});

export const ChangePasswordForm: FC<Props> = ({ onBack, onComplete }) => {
    const onChangePassword = useCallback(
        (input: ChangePasswordInput) => {
            return changePasswordRequest
                .request(getChangePasswordInput(input))
                .then(reloadUser)
                .then(onComplete)
                .then(() => messageStore.success('Пароль успешно изменен'));
        },
        [onComplete]
    );

    return (
        <Formik {...getChangePasswordFormik(onChangePassword)}>
            {formik => (
                <Form>
                    <Container>
                        {onBack ? <BackButton onBack={onBack} /> : null}
                        <FormikPasswordField required formik={formik} name="oldPassword" label="Старый пароль" />
                        <FormikPasswordField required formik={formik} name="newPassword" label="Новый пароль" />
                        <FormikPasswordField formik={formik} name="passwordConfirmation" label="Подтвердите пароль" />
                        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                            Изменить
                        </Button>
                    </Container>
                </Form>
            )}
        </Formik>
    );
};
