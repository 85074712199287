import React from 'react';
import { useHistory } from 'react-router';
import { HomeOutlined } from '@mui/icons-material';
import { ToolbarButton } from './ToolbarButton';

export const HomeButton = () => {
    const history = useHistory();

    return <ToolbarButton onClick={() => history.push('/')} Icon={HomeOutlined} label="Главная" color="secondary" />;
};
