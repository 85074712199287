import React, { FC, useMemo, useState } from 'react';
import { colors, Link, styled } from '@mui/material';
import { Text } from '../../../components/Text';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { formatCurrency, formatDate } from '../../../utils/formatters';
import { DepositOperation } from '../../../dto';

const DEFAULT_COUNT = 10;

type Props = {
    operations: DepositOperation[];
};

const Root = styled('div')({
    marginTop: '18px',
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
});

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

const Item = styled(BeveledContainer)({
    display: 'flex',
    flexDirection: 'row',
    padding: '11px 9px',
    gap: '9px',
    justifyContent: 'space-between',
});

const InfoArea = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '9px',
});

const Image = styled('img')({
    maxWidth: '24px',
    maxHeight: '24px',
});

const Info = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ClickableLink = styled(Link)({
    cursor: 'pointer',
});

type OperationProps = {
    operation: DepositOperation;
};

const Operation: FC<OperationProps> = ({ operation }) => {
    return (
        <Item>
            <InfoArea>
                <Image src={`data:image/jpeg;base64,${operation.typeIcon}`} />
                <Info>
                    <Text variant="SmallHeavy">{operation.typeName}</Text>
                    <Text variant="Tiny" color="secondary">
                        {formatDate(operation.date)}
                    </Text>
                </Info>
            </InfoArea>
            <Text variant="SmallHeavy">{formatCurrency(operation.sum)}</Text>
        </Item>
    );
};

export const DepositOperations: FC<Props> = ({ operations }) => {
    const [showAll, setShowAll] = useState(false);

    const sortedOperations = useMemo(() => operations.sort((a, b) => +b.date - +a.date), [operations]);

    const operationsToDisplay = showAll ? sortedOperations : sortedOperations.slice(0, DEFAULT_COUNT);

    return (
        <Root>
            <Text variant="TitleBold">Последние события</Text>
            {!!operations.length ? (
                <>
                    <Container>
                        {operationsToDisplay.map(operation => (
                            <Operation operation={operation} key={operation.id} />
                        ))}
                    </Container>
                    {!showAll && operationsToDisplay.length < operations.length && (
                        <ClickableLink onClick={() => setShowAll(true)}>
                            <Text variant="Small">Загрузить еще</Text>
                        </ClickableLink>
                    )}
                </>
            ) : (
                <Text variant="Body" color={colors.grey[600]}>
                    Операции отсутствуют
                </Text>
            )}
        </Root>
    );
};
