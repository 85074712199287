import { HttpRequest } from '../api/HttpRequest';
import { serializer, User } from '../dto';
import { FakeRequest } from '../api/FakeRequest';
import { mockApi } from '../api/constants';

export const meUserRequest = !mockApi
    ? new HttpRequest<void, User>({
          method: 'get',
          path: () => '/users/me/',
          mapper: data => serializer.deserializeObject(data, User) as User,
      })
    : new FakeRequest<void, User>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      id: '1',
                      name: 'Иванов',
                      surname: 'Иван',
                      patronymic: 'Иванович',
                      email: 'iii@gmail.com',
                      phone: '+79999999999',
                      status: 'ACTIVE',
                      force_password_change: false,
                  },
                  User
              ) as User,
      });
