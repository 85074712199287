import { PaymentAttributes, User } from './dto';

export enum UserStatus {
    DRAFT = 'DRAFT',
    NOT_VERIFIED = 'NOT_VERIFIED',
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
}

export enum DepositStatus {
    DRAFT = 'DRAFT',
    VERIFIED = 'VERIFIED',
    SIGNED = 'SIGNED',
    PAID = 'PAID',
    CLOSED = 'CLOSED',
}

export enum PaymentVariant {
    MONTHLY = 'MONTHLY',
    COMPLETION = 'COMPLETION',
}

export enum DocumentStatus {
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
    DENIED = 'DENIED',
}

export type ProfileDocumentType =
    | 'tin_scan'
    | 'snils_scan'
    | 'main'
    | 'registration'
    | 'marriage'
    | 'prev_passport'
    | 'selfie';

export type PaymentData = PaymentAttributes & {
    user: User;
    sum: number;
    purpose: string;
};
