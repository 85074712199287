import { Button, styled } from '@mui/material';
import React, { FC, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { PaymentData } from '../../../../types';
import { saveToClientURLContent } from '../../../../utils/files';

const QR_CODE_SIZE = 220;

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
});

const getQRCodeData = (paymentData: PaymentData) => {
    return `ST00012|Name=${paymentData.legalName}|PersonalAcc=${paymentData.account}|BankName=${
        paymentData.bankName
    }|BIC=${paymentData.bankBIK}|CorrespAcc=${paymentData.corAccount}|PayeeINN=${paymentData.TIN}|KPP=${
        paymentData.KPP
    }|LastName=${paymentData.user.surname}|FirstName=${paymentData.user.name}|${
        paymentData.user.patronymic ? 'MiddleName=' + paymentData.user.patronymic + '|' : ''
    }Purpose=${paymentData.purpose}|Sum=${Math.floor(paymentData.sum * 100)}`;
};

type Props = {
    paymentData: PaymentData;
};

export const PaymentQR: FC<Props> = ({ paymentData }) => {
    const value = getQRCodeData(paymentData);

    const ref = useRef<HTMLDivElement | null>(null);

    const download = () => {
        const canvas = ref.current?.querySelector('canvas');
        if (canvas) {
            const content = canvas.toDataURL('image/png');
            saveToClientURLContent(content, 'qrcode.png');
        }
    };

    return (
        <Root ref={ref}>
            <QRCodeCanvas size={QR_CODE_SIZE} value={value} />
            <Button variant={'contained'} fullWidth onClick={download}>
                Сохранить QR-код
            </Button>
        </Root>
    );
};
