import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class PaymentAttributes {
    @JsonProperty({ name: 'legal_name' })
    legalName: string = '';

    @JsonProperty({ name: 'bank_name' })
    bankName: string = '';

    @JsonProperty({ name: 'bank_bik' })
    bankBIK: string = '';

    @JsonProperty()
    account: string = '';

    @JsonProperty({ name: 'cor_account' })
    corAccount: string = '';

    @JsonProperty({ name: 'tin' })
    TIN: string = '';

    @JsonProperty({ name: 'kpp' })
    KPP: string = '';
}
