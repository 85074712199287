import { FormikContextType } from 'formik';
import React from 'react';
import { ButtonSwitch } from '../ButtonSwitch';

type FormikSexFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends string ? TFieldName : never;
    formik: FormikContextType<TValues>;
};

const options = [
    { label: 'Мужской', value: 'M' },
    { label: 'Женский', value: 'F' },
];

export function FormikSexField<TValues extends {}, TFieldName extends keyof TValues & string>({
    name,
    formik,
}: FormikSexFieldProps<TValues, TFieldName>) {
    const value = formik.values[name] as string;
    const handleChange = (v: string) => {
        formik.setFieldTouched(name);
        formik.setFieldValue(name, v);
    };

    return <ButtonSwitch value={value} onChange={handleChange} options={options} />;
}
