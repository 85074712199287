import { FakeRequest } from '../../api/FakeRequest';
import { ProfileInput, getProfileInput, serializer } from '../../dto';
import { mockApi } from '../../api/constants';
import { HttpRequest } from '../../api/HttpRequest';

export const finishRegistrationRequest = !mockApi
    ? new HttpRequest<ProfileInput, void>({
          method: 'post',
          path: () => '/users/profile/',
          mapper: data => data,
          body: data => serializer.serializeObject(getProfileInput(data)) || {},
      })
    : new FakeRequest<ProfileInput, void>({
          fakeData: _ => ({}),
      });
