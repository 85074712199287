import { FormikContextType } from 'formik';
import { Button, colors, FormControl, FormLabel, IconButton, styled, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownLoadIcon from '@mui/icons-material/Download';
import SuccessIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import WaitIcon from '@mui/icons-material/AccessTime';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { FileInfo } from '../../dto';
import { DocumentStatus } from '../../types';
import { formatDocumentStatus } from '../../utils/formatters';

export const selectButtonStyle: React.CSSProperties = {
    height: '32px',
    width: 180,
    borderRadius: 4,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    color: colors.grey[600],
    borderColor: colors.grey[600],
    marginRight: 12,
};

export const Img = styled('img')(() => ({
    width: 40,
    height: 40,
    marginRight: 16,
}));

type FormikFileFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends File | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    onDownload?: () => void;
    fileInfo?: FileInfo;
};

const Label = styled(FormLabel)(({ theme }) => ({
    color: theme.palette.grey[800],
    fontSize: '0.8em',
    fontWeight: 500,
}));

const icons: Record<DocumentStatus, () => ReactNode> = {
    APPROVED: () => <SuccessIcon color="primary" />,
    NOT_APPROVED: () => <WaitIcon />,
    DENIED: () => <ErrorIcon color="secondary" />,
};

export const FormikFileField = <TValues extends {}, TFieldName extends keyof TValues & string>({
    label,
    name,
    formik,
    required,
    disabled: externalDisabled,
    onDownload,
    fileInfo,
}: FormikFileFieldProps<TValues, TFieldName>) => {
    const value = formik.values[name] as File | null;
    const error = formik.errors[name]?.toString();
    const touched = formik.touched[name];

    const [src, setSrc] = useState('');

    useEffect(() => {
        if (value) {
            const url = URL.createObjectURL(value);
            setSrc(url);

            return () => URL.revokeObjectURL(url);
        } else {
            setSrc('');
        }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0]!;

            e.target.value = '';
            formik.setFieldValue(name, file, true).then(() => formik.setFieldTouched(name, true));
        }
    };

    const handleClear = () => {
        formik.setFieldValue(name, null, true);
        formik.setFieldTouched(name, true);
    };

    const hasFormikError = !!touched && !!error;
    const formikError = touched ? error : undefined;
    const fileStatus = fileInfo ? formatDocumentStatus(fileInfo.status) : undefined;
    const fileIcon = fileInfo ? icons[fileInfo.status]() : undefined;
    const disabled =
        !!externalDisabled ||
        fileInfo?.status === DocumentStatus.APPROVED ||
        fileInfo?.status === DocumentStatus.NOT_APPROVED;

    return (
        <FormControl>
            <Label color="primary">{label}</Label>
            <TextField
                required={required}
                variant="outlined"
                InputLabelProps={{ shrink: true, style: { whiteSpace: 'normal' } }}
                value={value ? value.name : fileInfo?.name || ''}
                disabled={!!value || disabled}
                error={value ? hasFormikError : fileInfo?.status === DocumentStatus.DENIED}
                helperText={value ? formikError : fileStatus}
                InputProps={
                    value
                        ? {
                              startAdornment: <Img alt="preview" src={src} />,
                              endAdornment: (
                                  <IconButton onClick={handleClear} disabled={disabled}>
                                      <CloseIcon />
                                  </IconButton>
                              ),
                          }
                        : {
                              startAdornment: disabled ? (
                                  fileIcon
                              ) : (
                                  <Button style={selectButtonStyle} variant="outlined" component="label">
                                      + Выбрать файл
                                      <input type="file" accept="image" hidden onChange={handleChange} />
                                  </Button>
                              ),
                              endAdornment: !!onDownload ? (
                                  <IconButton onClick={onDownload}>
                                      <DownLoadIcon />
                                  </IconButton>
                              ) : undefined,
                          }
                }
            />
        </FormControl>
    );
};
