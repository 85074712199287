import React, { FC } from 'react';
import { styled } from '@mui/material';
import { DepositHeader } from './DepositHeader';
import { DepositParams } from './DepositParams';
import { DepositDocuments } from './DepositDocuments';
import { DepositActions } from './DepositActions';
import { DepositOperations } from './DepositOperations';
import { isDepositActive } from '../../../utils/deposits';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { DepositDetailedInfo } from '../../../dto';

type Props = {
    deposit: DepositDetailedInfo;
    onPaymentSelect: (sum: number) => void;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const DepositCard: FC<Props> = ({ deposit, onPaymentSelect }) => {
    return (
        <Root>
            <Breadcrumbs paths={[{ title: 'инвестиции', link: '/' }, { title: 'вклад' }]} />
            <DepositHeader deposit={deposit} />
            <DepositParams deposit={deposit} />
            <DepositDocuments deposit={deposit} />
            <DepositActions deposit={deposit} onPaymentSelect={onPaymentSelect} />
            {isDepositActive(deposit) && <DepositOperations operations={deposit.operations} />}
        </Root>
    );
};
