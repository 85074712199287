import { PaymentData } from '../../../../types';
import { Button, LinearProgress, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { DepositHeader } from '../DepositHeader';
import { BackButton } from '../../../../components/Layout';
import { PaymentQR } from './PaymentQR';
import { PaymentManual } from './PaymentManual';
import { useRequest } from '../../../../hooks/useRequest';
import { paymentAttributesRequest } from './paymentAttributesRequest';
import { authStore } from '../../../../store/authStore';
import { getPaymentPurpose } from '../../../../utils/deposits';
import { DepositDetailedInfo } from '../../../../dto';

type Props = {
    deposit: DepositDetailedInfo;
    sum: number;
    onBack: () => void;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

const Buttons = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
});

const SelectModeButton = styled(Button)({
    width: '100%',
});

type Mode = 'QR' | 'Manual';

export const PaymentScreen: FC<Props> = ({ deposit, sum, onBack }) => {
    const { data, error, loading } = useRequest(paymentAttributesRequest, undefined);

    const [mode, setMode] = React.useState<Mode | null>(null);

    const onBackClick = () => {
        if (!mode) {
            onBack();
        } else {
            setMode(null);
        }
    };

    const paymentData: PaymentData | null =
        data && authStore.user
            ? {
                  user: authStore.user,
                  sum,
                  purpose: getPaymentPurpose(deposit),
                  ...data,
              }
            : null;

    return (
        <Root>
            {loading && <LinearProgress />}
            {error && <Typography color="error">{error.message}</Typography>}
            {data && paymentData && (
                <>
                    <BackButton onBack={onBackClick} />
                    <DepositHeader deposit={deposit} />
                    {!mode && (
                        <Buttons>
                            <SelectModeButton onClick={() => setMode('QR')} variant="outlined">
                                Оплата по QR-коду
                            </SelectModeButton>
                            <SelectModeButton onClick={() => setMode('Manual')} variant="outlined">
                                Оплата по реквизитам
                            </SelectModeButton>
                        </Buttons>
                    )}
                    {mode === 'QR' && <PaymentQR paymentData={paymentData} />}
                    {mode === 'Manual' && <PaymentManual paymentData={paymentData} />}
                </>
            )}
        </Root>
    );
};
