import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DepositCondition } from './DepositConditionDto';

@JsonObject()
export class DepositType {
    @JsonProperty()
    id: string = '';

    @JsonProperty()
    name: string = '';

    @JsonProperty({ type: DepositCondition })
    conditions: DepositCondition[] = [];
}
