import { IRequest } from './types';
import { ProgressController } from './ProgressController';
import { computed, makeObservable } from 'mobx';
import { baseApiUrl, TOKEN_EXPIRED_STATUS } from './constants';
import { authStore } from '../store/authStore';
import { errorHandler } from './apiHelpers';
import { refreshTokenRequest } from './refreshTokenRequest';

type HttpRequestConfig<Input, Output> = {
    method: 'get' | 'post' | 'put';
    path: (input: Input) => string;
    body?: (input: Input) => object;
    mapper: (data: any) => Output;
};

export class HttpRequest<Input, Output> implements IRequest<Input, Output> {
    constructor(private config: HttpRequestConfig<Input, Output>) {
        makeObservable(this);
    }

    private progress = new ProgressController();

    @computed
    get isLoading() {
        return this.progress.inProgress;
    }

    static async refreshToken(): Promise<boolean> {
        const refresh = authStore.refreshToken;

        if (!refresh) return false;

        const result = await refreshTokenRequest(refresh);
        if (result) {
            authStore.setAuthToken(result);
            return true;
        } else {
            return false;
        }
    }

    async request(input: Input): Promise<Output> {
        try {
            this.progress.startProgress();
            const url = baseApiUrl + this.config.path(input);
            const token = authStore.authToken;
            const headers = {
                Authorization: token ? `Bearer ${token}` : '',
                accept: 'application/json',
                'content-type': 'application/json',
            };
            const body = this.config.body ? JSON.stringify(this.config.body(input)) : null;

            const result = await fetch(url, {
                method: this.config.method,
                headers,
                body,
            });

            if (result.status === TOKEN_EXPIRED_STATUS && token) {
                const success = await HttpRequest.refreshToken();

                if (success) {
                    return this.request(input);
                } else {
                    authStore.logout();

                    return Promise.reject(new Error('Token refresh failed, logout'));
                }
            }

            const data = await result.json().catch(() => null);

            if (!data) {
                if (result.status > 299) {
                    throw new Error('Не удалось получить ответ');
                }

                return {} as Output;
            }

            if (result.status > 299) {
                throw new Error(JSON.stringify(data));
            }

            return this.config.mapper(data);
        } catch (e) {
            errorHandler(e);

            throw e;
        } finally {
            this.progress.stopProgress();
        }
    }
}
