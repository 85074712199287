import { sanitizePhone } from './utils';

const emailRE =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneRE = /^[7][0-9]{10}$/;

export const isNotEmpty = (value?: string | null): value is string => {
    return typeof value === 'string' && value.trim().length > 0;
};

export const isValidEmail = (email: string | null | undefined) => {
    return isNotEmpty(email) && emailRE.test(email.trim());
};

export const isValidPhone = (phone: string | null | undefined) => {
    return isNotEmpty(phone) && phoneRE.test(sanitizePhone(phone));
};
