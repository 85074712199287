import React, { FC } from 'react';
import { Stepper as MuiStepper, Step as MuiStep, StepLabel, Button, styled } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export type Step = {
    caption?: string;
    component: React.ReactNode;
};

type Props = {
    activeStep: number;
    nextStep: () => void;
    prevStep: () => void;
    steps: Step[];
    onComplete: () => void;
    completeLabel?: string;
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
}));

const ButtonsArea = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

export const Stepper: FC<Props> = ({ steps, activeStep, nextStep, prevStep, onComplete, completeLabel = 'Готово' }) => {
    const hasNext = (index: number) => index < steps.length - 1;
    const hasPrev = (index: number) => index > 0;

    return (
        <Root>
            <MuiStepper activeStep={activeStep}>
                {steps.map(({ caption }, index) => (
                    <MuiStep key={index} completed={index < activeStep}>
                        <StepLabel>{caption}</StepLabel>
                    </MuiStep>
                ))}
            </MuiStepper>
            {steps[activeStep].component}
            <ButtonsArea>
                <Button
                    variant="outlined"
                    style={hasPrev(activeStep) ? undefined : { visibility: 'hidden' }}
                    onClick={prevStep}
                >
                    <KeyboardArrowLeft />
                    Назад
                </Button>

                {hasNext(activeStep) ? (
                    <Button variant="contained" color="primary" onClick={nextStep}>
                        Следующий шаг
                        <KeyboardArrowRight />
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={onComplete}>
                        {completeLabel}
                    </Button>
                )}
            </ButtonsArea>
        </Root>
    );
};
