import { IRequest } from './types';
import { ProgressController } from './ProgressController';
import { errorHandler } from './apiHelpers';
import { computed } from 'mobx';

type FakeRequestConfig<Input, Output> = {
    fakeData: (input: Input) => Output;
    shouldThrow?: boolean;
};

const REQUEST_TIME = 1500;

export class FakeRequest<Input, Output> implements IRequest<Input, Output> {
    constructor(private config: FakeRequestConfig<Input, Output>) {}

    private progress = new ProgressController();

    @computed
    get isLoading() {
        return this.progress.inProgress;
    }

    async request(input: Input) {
        this.progress.startProgress();
        await new Promise(resolve => setTimeout(resolve, REQUEST_TIME));
        this.progress.stopProgress();

        if (this.config.shouldThrow) {
            const error = new Error('Fake request error');
            errorHandler(error);
            return Promise.reject(error);
        } else {
            return this.config.fakeData(input);
        }
    }
}
