import { HttpRequest } from '../../api/HttpRequest';
import { Profile, serializer } from '../../dto';
import { mockApi } from '../../api/constants';
import { FakeRequest } from '../../api/FakeRequest';
import { DocumentStatus } from '../../types';

export const profileRequest = !mockApi
    ? new HttpRequest<void, Profile>({
          method: 'get',
          path: () => '/users/profile/',
          mapper: data => serializer.deserializeObject(data, Profile) as Profile,
      })
    : new FakeRequest<void, Profile>({
          fakeData: () =>
              Object.assign(new Profile(), {
                  SNILSScan: { id: '1', name: 'снилс.pdf', status: DocumentStatus.DENIED },
                  TINScan: { id: '2', name: 'инн.jpg', status: DocumentStatus.APPROVED },
              }),
      });
