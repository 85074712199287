import { Route, Switch } from 'react-router-dom';
import { LoginPage } from './LoginPage';
import { RegisterPage } from './RegisterPage';

export const LoginRegisterNavigation = () => {
    return (
        <Switch>
            <Route path="/register" component={RegisterPage} />
            <Route path="/" component={LoginPage} />
        </Switch>
    );
};
