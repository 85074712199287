import { FakeRequest } from '../../../../api/FakeRequest';
import { PaymentAttributes, serializer } from '../../../../dto';
import { mockApi } from '../../../../api/constants';
import { HttpRequest } from '../../../../api/HttpRequest';

export const paymentAttributesRequest = !mockApi
    ? new HttpRequest<void, PaymentAttributes>({
          method: 'get',
          path: () => '/accounting/payment-attributes/',
          mapper: data => serializer.deserializeObject(data, PaymentAttributes) as PaymentAttributes,
      })
    : new FakeRequest<void, PaymentAttributes>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                      legal_name: 'Нурфинанс',
                      bank_name: 'ОТДЕЛЕНИЕ "БАНК ТАТАРСТАН" N8610 ПАО СБЕРБАНК г. Казань',
                      bank_bik: '049205603',
                      account: '40702810362000100917',
                      cor_account: '30101810600000000603',
                      tin: '9702021960',
                      kpp: '168601001',
                  },
                  PaymentAttributes
              ) as PaymentAttributes,
      });
