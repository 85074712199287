import React, { FC, useState } from 'react';
import { useRegisterFormik } from './RegisterFormik';
import { FormikCheckBoxField, FormikPhoneField, FormikTextField } from '../../components/FormikFields';
import { Box, Button, FormHelperText, styled, Typography } from '@mui/material';
import { AgreementDialog } from './AgreementDialog';
import { Form } from 'formik';
import { SmartCaptcha } from '@yandex/smart-captcha';
import { CAPTCHA_SITEKEY } from '../../api/constants';
import { Layout } from '../../components/Layout';
import { Breadcrumbs } from '../../components/Breadcrumbs';

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
}));

type Props = {
    onClick: () => void;
};

const LinkToAgreement: FC<Props> = ({ onClick }) => {
    return (
        <div
            onClick={e => {
                e.preventDefault();
                onClick();
            }}
        >
            <Typography variant={'body1'}>
                Нажимая кнопку «Продолжить», я даю свое согласие на обработку моих персональных данных.
            </Typography>
            <Typography color="blueviolet">Открыть условия обработки персональных данных</Typography>
        </div>
    );
};

export const RegisterForm: FC = () => {
    const formik = useRegisterFormik();
    const [open, setOpen] = useState(false);

    return (
        <Layout title={'Регистрация'}>
            <Form>
                <Root>
                    <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', fontSize: 'h6.fontSize' }}>Регистрация в личном кабинете</Box>
                    </Typography>
                    <FormikTextField formik={formik} name="name" label="Имя" required variant="outlined" />
                    <FormikTextField formik={formik} name="surname" label="Фамилия" required variant="outlined" />
                    <FormikTextField formik={formik} name="patronymic" label="Отчество" variant="outlined" />
                    <FormikPhoneField formik={formik} name="phone" label="Телефон" required variant="outlined" />
                    <FormikTextField
                        formik={formik}
                        name="email"
                        label="Электронная почта"
                        type="email"
                        required
                        variant="outlined"
                    />
                    <FormikCheckBoxField
                        formik={formik}
                        name={'agreementChecked'}
                        label={
                            <>
                                <LinkToAgreement onClick={() => setOpen(true)} />
                            </>
                        }
                        required
                    />
                    <AgreementDialog open={open} onClose={() => setOpen(false)} />

                    <SmartCaptcha
                        key={formik.values.failedRequestCount}
                        sitekey={CAPTCHA_SITEKEY}
                        onSuccess={token => {
                            formik.setFieldValue('smartToken', token);
                            formik.setFieldError('smartToken', undefined);
                        }}
                        onJavascriptError={error => {
                            formik.setFieldError('smartToken', error.message);
                        }}
                        onTokenExpired={() => {
                            formik.setFieldError('smartToken', 'Token expired');
                        }}
                        language="ru"
                    />
                    {formik.touched['smartToken'] && formik.errors['smartToken']?.length && (
                        <FormHelperText error>{formik.errors['smartToken']}</FormHelperText>
                    )}

                    <Button fullWidth variant="contained" color="primary" onClick={() => formik.handleSubmit()}>
                        Продолжить
                    </Button>
                </Root>
            </Form>
        </Layout>
    );
};
