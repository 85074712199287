import React, { FC } from 'react';
import { styled } from '@mui/material';
import { Text } from '../../../components/Text';
import { getDepositStatusText } from '../../../utils/deposits';
import { DepositDetailedInfo } from '../../../dto';
import { formatDate } from '../../../utils/formatters';

type Props = {
    deposit: DepositDetailedInfo;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

export const DepositHeader: FC<Props> = ({ deposit }) => {
    return (
        <Root>
            <Row>
                <Text variant="LargeTitle">{deposit.alias || deposit.name}</Text>
                <Text variant="LargeTitle" color="secondary">
                    {deposit.contractNumber}
                </Text>
            </Row>
            <Row>
                <Text variant="Small" color="secondary">
                    {getDepositStatusText(deposit)}
                </Text>
            </Row>
            <Row>
                <Text variant="Small" color="secondary">
                    Вклад от {formatDate(deposit.contractDate)}
                </Text>
                <Text variant="Small" color="secondary">
                    Срок, месяцев: {deposit.duration}
                </Text>
            </Row>
        </Root>
    );
};
