import { mockApi } from '../../api/constants';
import { FakeRequest } from '../../api/FakeRequest';
import { HttpRequest } from '../../api/HttpRequest';
import { Tokens } from '../../dto';

export const loginRequest = !mockApi
    ? new HttpRequest<{ email: string; password: string }, Tokens>({
          method: 'post',
          path: () => '/users/login/',
          body: b => b,
          mapper: response => {
              if (!response.access || !response.refresh)
                  throw new Error('Unexpected login response: ' + JSON.stringify(response));
              return response;
          },
      })
    : new FakeRequest<{ email: string; password: string }, Tokens>({
          fakeData: () => ({
              access: 'access',
              refresh: 'refresh',
          }),
      });
