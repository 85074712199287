import React, { FC } from 'react';
import { DepositDetailedInfo } from '../../../dto';
import { DepositCard } from './DepositCard';
import { PaymentScreen } from './PaymentScreen';

type Props = {
    deposit: DepositDetailedInfo;
};

export const Deposit: FC<Props> = ({ deposit }) => {
    const [sumToPay, setSumToPay] = React.useState<number | null>(null);

    return !sumToPay ? (
        <DepositCard deposit={deposit} onPaymentSelect={value => setSumToPay(value)} />
    ) : (
        <PaymentScreen deposit={deposit} sum={sumToPay} onBack={() => setSumToPay(null)} />
    );
};
