import { Button, styled, ButtonProps } from '@mui/material';
import React from 'react';
import { Text } from '../Text';
import { SvgIconComponent } from '@mui/icons-material';

type Props = {
    label: React.ReactNode;
    Icon: SvgIconComponent;
    color: ButtonProps['color'];
    onClick: () => void;
};

const SquareButton = styled(Button)({
    borderRadius: 4,
    height: 66,
    width: 66,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'inherit',
});

export const ToolbarButton = ({ label, Icon, onClick, color }: Props) => {
    return (
        <SquareButton onClick={onClick} color={color}>
            <Icon fontSize="large" />
            <Text variant="Small">{label}</Text>
        </SquareButton>
    );
};
