import { useRequest } from '../../hooks/useRequest';
import { depositTypesRequest } from '../../store/depositTypesRequest';
import { useCallback, useEffect, useState } from 'react';
import { depositCalculationsStore } from '../../store/depositCalculationsStore';
import { Box, Button, CircularProgress, styled } from '@mui/material';
import { Layout } from '../../components/Layout';
import { CreateDepositOptions } from '../common/CreateDepositOptions';
import { createDepositRequest } from './createDepositRequest';
import { getDepositInput } from '../../dto/DepositInputDto';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { authStore } from '../../store/authStore';
import { ProfileForm } from '../common/ProfileForm';
import { Formik } from 'formik';
import { IProfileValues, getProfileFormik } from '../common/ProfileForm/profileFormik';
import { ProfileInput, getFileInput } from '../../dto';
import { messageStore } from '../../store/messageStore';
import { reloadUser } from '../../utils/users';
import { finishRegistrationRequest } from '../FinishRegistrationPage/finishRegistrationRequest';
import { Stepper } from '../../components/Stepper';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ConfirmCreateDepositOptions } from '../common/CreateDepositOptions/ConfirmCreateDepositOptions';
import { averageProfitabilityRequest } from '../../store/averageProfitabilityRequest';

const Root = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const CreateDepositAndFinishRegistration = observer(() => {
    const { data: depositTypesRequestData, loading: depositTypesRequestLoading } = useRequest(depositTypesRequest, {});
    const { data: averageProfitabilityRequestData, loading: averageProfitabilityRequestLoading } = useRequest(
        averageProfitabilityRequest,
        undefined
    );
    const { user } = authStore;

    const history = useHistory();

    const loading = depositTypesRequestLoading || averageProfitabilityRequestLoading;

    useEffect(() => {
        depositCalculationsStore.init(
            depositTypesRequestData || [],
            averageProfitabilityRequestData?.averageProfitability || ''
        );
    }, [depositTypesRequestData, averageProfitabilityRequestData]);

    const onCreate = useCallback(() => {
        return createDepositRequest
            .request(
                getDepositInput({
                    depositId: depositCalculationsStore.depositType?.id || '0',
                    duration: depositCalculationsStore.duration || 0,
                    firstPayment: depositCalculationsStore.sum || 0,
                    alias: depositCalculationsStore.alias,
                })
            )
            .then(() => history.push('/'));
    }, [history]);

    const isCreating = createDepositRequest.isLoading;

    const [activeStep, setActiveStep] = useState<number>(0);

    async function submit(values: IProfileValues) {
        const {
            passportMain,
            passportRegistration,
            passportSelfie,
            bankName,
            BIK,
            bankAccount,
            correspondentAccount,
            SNILSScan,
            bankInfoScan,
            issuedAt,
            birthDate,
            ...rest
        } = values;

        const input: ProfileInput = {
            ...rest,
            issuedAt: issuedAt!,
            birthDate: birthDate!,
            passportScans: {
                main: await getFileInput(passportMain!),
                registration: await getFileInput(passportRegistration!),
                selfie: await getFileInput(passportSelfie!),
            },
            bankInfo: {
                bankName,
                BIK,
                account: bankAccount,
                correspondentAccount,
            },
            SNILSScan: await getFileInput(SNILSScan!),
            bankInfoScan: await getFileInput(bankInfoScan!),
        };

        await finishRegistrationRequest
            .request(input)
            .then(() => messageStore.success('Регистрация завершена, дождитесь верификации учетной записи'))
            .then(reloadUser)
            .then(() => {
                return onCreate();
            });
    }

    if (!user) return <CircularProgress />;

    return (
        <Layout title={'Новый продукт'}>
            <Root>
                <Breadcrumbs paths={[{ title: 'инвестиции', link: '/' }, { title: 'вклад' }]} />
                <Formik {...getProfileFormik(user, null, submit)}>
                    {formik => (
                        <Stepper
                            activeStep={activeStep}
                            nextStep={() => {
                                if (depositCalculationsStore.error) {
                                    return;
                                }
                                setActiveStep(step => step + 1);
                            }}
                            prevStep={() => setActiveStep(step => step - 1)}
                            steps={[
                                { caption: 'Открыть новый продукт', component: <CreateDepositOptions /> },
                                { caption: 'Подтвердите выбор', component: <ConfirmCreateDepositOptions /> },
                                {
                                    caption: 'Завершите регистрацию',
                                    component: <ProfileForm disabled={isCreating || loading} formik={formik} />,
                                },
                            ]}
                            onComplete={() => formik.handleSubmit()}
                            completeLabel="Отправить"
                        />
                    )}
                </Formik>
            </Root>
        </Layout>
    );
});
