import { Button, styled } from '@mui/material';
import React, { FC } from 'react';
import { PaymentData } from '../../../../types';
import { Text } from '../../../../components/Text';
import { formatCurrency } from '../../../../utils/formatters';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
});

const TextArea = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const getTextPayment = (paymentData: PaymentData): string[] => {
    return [
        `Банк: ${paymentData.bankName}`,
        `БИК: ${paymentData.bankBIK}`,
        `р/с: ${paymentData.account}`,
        `к/с: ${paymentData.corAccount}`,
        `ИНН: ${paymentData.TIN}`,
        `КПП: ${paymentData.KPP}`,
        `Назначение платежа: ${paymentData.purpose}`,
        `Сумма к оплате: ${formatCurrency(paymentData.sum)}`,
    ];
};

type Props = {
    paymentData: PaymentData;
};

export const PaymentManual: FC<Props> = ({ paymentData }) => {
    const value = getTextPayment(paymentData);

    return (
        <Root>
            <TextArea>
                {value.map((line, i) => (
                    <Text variant="Title" key={i.toString()}>
                        {line}
                    </Text>
                ))}
            </TextArea>
            <Button variant={'contained'} fullWidth onClick={() => navigator.clipboard.writeText(value.join('\n'))}>
                Скопировать реквизиты
            </Button>
        </Root>
    );
};
