import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { JsonDate } from './util';
import { toBase64 } from '../utils/files';

export type FileInput = string;

@JsonObject()
class PassportScans {
    @JsonProperty()
    main?: string;

    @JsonProperty()
    registration?: string;

    @JsonProperty()
    selfie?: string;
}

@JsonObject()
class BankInfo {
    @JsonProperty({ name: 'bank_name' })
    bankName?: string;

    @JsonProperty({ name: 'bik' })
    BIK?: string;

    // TODO Убрать алиас после исправления серверного API
    @JsonProperty({ name: 'payment_account' })
    account?: string;

    @JsonProperty({ name: 'correspondent_account' })
    correspondentAccount?: string;
}

@JsonObject()
export class ProfileInput {
    @JsonProperty({ name: 'first_name' })
    name?: string;

    @JsonProperty({ name: 'last_name' })
    surname?: string;

    @JsonProperty({ name: 'middle_name' })
    patronymic?: string | null;

    @JsonProperty()
    phone?: string;

    @JsonProperty()
    sex?: string;

    @JsonDate({ name: 'birth_date' })
    birthDate?: Date;

    @JsonProperty({ name: 'passport_seria' })
    passportSeria?: string;

    @JsonProperty({ name: 'passport_number' })
    passportNumber?: string;

    @JsonProperty({ name: 'issued_by' })
    issuedBy?: string;

    @JsonDate({ name: 'issued_at' })
    issuedAt?: Date;

    @JsonProperty({ name: 'passport_dpt_code' })
    passportDptCode?: string;

    @JsonProperty({ name: 'birth_place' })
    birthPlace?: string;

    @JsonProperty({ name: 'reg_address' })
    regAddress?: string;

    @JsonProperty({ name: 'live_address' })
    liveAddress?: string;

    @JsonProperty({ name: 'tin' })
    TIN?: string;

    @JsonProperty({ name: 'snils' })
    SNILS?: string;

    @JsonProperty({ type: PassportScans, name: 'passport_scans' })
    passportScans?: PassportScans;

    @JsonProperty({ name: 'snils_scan' })
    SNILSScan?: string;

    @JsonProperty({ name: 'account_scan' })
    bankInfoScan?: string;

    @JsonProperty({ type: BankInfo, name: 'bank_info' })
    bankInfo?: BankInfo;
}

export const getFileInput = async (file: File): Promise<FileInput> => {
    return toBase64(file);
};

export const getProfileInput = (input: ProfileInput): ProfileInput => {
    const passportScans = Object.assign(new PassportScans(), input.passportScans);
    const bankInfo = Object.assign(new BankInfo(), input.bankInfo);

    return Object.assign(new ProfileInput(), { ...input, passportScans, bankInfo });
};
