import { baseApiUrl } from './constants';
import { Tokens } from '../dto';

export const refreshTokenRequest = async (refresh: string): Promise<Tokens | null> => {
    try {
        const res = await fetch(baseApiUrl + '/users/refresh/', {
            method: 'post',
            body: JSON.stringify({ refresh }),
            headers: { accept: 'application/json', 'content-type': 'application/json' },
        });

        const response: Tokens = await res.json();

        return response;
    } catch (e) {
        console.log(e);

        return null;
    }
};
