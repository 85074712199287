import { observable, action, computed, makeObservable } from 'mobx';

export class ProgressController {
    constructor() {
        makeObservable(this);
    }

    @observable
    private count: number = 0;

    @computed
    public get inProgress() {
        return this.count > 0;
    }

    @action
    startProgress() {
        this.count++;
    }

    @action
    stopProgress() {
        this.count--;
    }
}
