import { styled } from '@mui/material';
import { Colors } from '../../theme/colors';

export const BeveledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px 20px 4px 20px',
    borderWidth: '1px',
    borderColor: Colors.lightBlue,
    borderStyle: 'solid',
    padding: '15px',
});
