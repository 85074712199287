import { FormikConfig } from 'formik';
import { ChangePasswordInput } from '../../dto';
import * as yup from 'yup';

export const getChangePasswordFormik = (
    submit: (input: ChangePasswordInput) => Promise<unknown>
): FormikConfig<ChangePasswordInput> => {
    return {
        initialValues: {
            oldPassword: '',
            newPassword: '',
            passwordConfirmation: '',
        },
        onSubmit: async values => {
            return submit(values);
        },
        validationSchema: yup.object().shape({
            oldPassword: yup.string().required('Укажите старый пароль'),
            newPassword: yup
                .string()
                .required('Укажите новый пароль')
                .notOneOf([yup.ref('oldPassword')], 'Новый пароль должен отличаться от старого'),
            passwordConfirmation: yup.string().oneOf([yup.ref('newPassword')], 'Пароли не совпадают'),
        }),
    };
};
