import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';
import { loginRequest } from './loginRequest';
import { authStore } from '../../store/authStore';

class LoginStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    login = '';

    @observable
    password = '';

    @action
    onLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.login = e.target.value;
    };

    @action
    onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.password = e.target.value;
    };

    @computed
    get canSubmit() {
        return !!this.login && !!this.password;
    }

    submit = () => {
        const { login, password } = this;
        loginRequest
            .request({ email: login, password })
            .then(tokens => {
                authStore.setAuthToken(tokens);
            })
            .catch(() => {}); // Сообщение показано в request
    };
}

export const loginStore = new LoginStore();
